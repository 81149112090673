import { Grid, Paper, TabProps, Typography, makeStyles, createStyles, } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../state";
import { updateTabContainers } from "../state/tabsReducer";
import { StatusIcon } from "./statusIcon";
import { TabsContainer, TabsContainerProps } from "./tabs";
import XmlFormatter from "xml-formatter";


type TestResultState = {
  responseType: string;
  errorMessage: String;
  isComplete: Boolean;
  isSuccess: Boolean;
  payload: string | null;
  responsePayload: string | null;
}

type TestPingResultProps = {
  formId: string;  
  tabContainer?: any;
  dispatch?: Function;
  formData?: any;
  result?: TestResultState;
};

const parseTest = (test:any) => {  
  let result: TestResultState | undefined = undefined;
  const route = test?.params?.Route || undefined;
  switch (route) {
    case 'BuildPingRequest':
    case 'TestPing':
      result = {
        responseType: "Ping Response",
        errorMessage: test?.testPing?.pingResponse?.errorMessage || test?.testPing?.errorMessage,
        isComplete: test?.testPing?.pingResponse?.isComplete || false,
        isSuccess: test?.testPing?.pingResponse?.isSuccess || false,
        payload: test?.testPing?.pingResponse?.requestPayload || null,
        responsePayload: test?.testPing?.pingResponse?.responsePayload || null
      }; break;

    case 'BuildPostRequest':
    case 'TestPost':
      result = {
        responseType: "Post Response",
        errorMessage: test?.testPing?.postResponse?.errorMessage || test?.testPing?.errorMessage,
        isComplete: test?.testPing?.postResponse?.isComplete || false,
        isSuccess: test?.testPing?.postResponse?.isSuccess || false,
        payload: test?.testPing?.postResponse?.requestPayload || null,
        responsePayload: test?.testPing?.postResponse?.responsePayload || null
      }; break;
  }
  return result;
}

const TestPingResult = ({
    formId,    
    tabContainer,
    dispatch,
    formData,
    result,
}:TestPingResultProps) => {

  const classes = useStyles();
  const rawTestResult = formData?.postedTestPing;
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [pingTestTabs, setPingTestTabs] = useState<TabProps[]>();

  useEffect(() => {    
    if(result){
      console.log(result?.payload);
      if (!tabContainer) {
        const initialTabs = [
          {
            title: "Test Summary",
            content: (<Grid container spacing={2} className={classes.tabContent}>
              <Grid item className={classes.responseTitle} xs={12}>
                {result.responseType}
              </Grid>
              <Grid item xs={12} lg={12} className={classes.pingResponseContainer}>
                <Grid container spacing={1}>
                  <Grid item className={classes.responseTitle} xs={12} lg={2}>
                    Error Message:
                  </Grid>
                  <Grid item xs={12} lg={10} style={{ maxHeight: "230px", overflow: "hidden auto" }}>
                    <Paper className={classes.CodeWrapper}>{result.errorMessage ?? rawTestResult?.errorMessage}</Paper>
                  </Grid>
                  <Grid item className={classes.responseTitle} xs={6} lg={2}>
                    Is Complete:
                  </Grid>
                  <Grid item xs={6} lg={10}>
                    <StatusIcon value={result.isComplete} />
                  </Grid>
                  <Grid item className={classes.responseTitle} xs={6} lg={2}>
                    Is Success:
                  </Grid>
                  <Grid item xs={6} lg={10}>
                    <StatusIcon value={result.isSuccess} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>),
          },
          {
            title: "Full Response",
            content: (<Grid item xs={12} className={classes.tabContent}>
              <textarea style={{ height: "230px", width: "100%" }} readOnly={true} defaultValue={JSON.stringify(rawTestResult, null, 2)} />
            </Grid>),
          },
          {
            title: "Request Payload",
            content: (<Grid item xs={12} className={classes.tabContent}>
              <textarea style={{ height: "230px", width: "100%" }} readOnly={true} defaultValue={`${result?.payload || ''}`} />
            </Grid>),
          },
          {
            title: "Response Payload",
            content: (<Grid item xs={12} className={classes.tabContent}>
              <textarea style={{ height: "230px", width: "100%" }} readOnly={true} defaultValue={`${result?.responsePayload?.startsWith('<?xml') ? XmlFormatter(result?.responsePayload) : (result?.responsePayload || '')}`} />
            </Grid>),
          }
        ];

        const initialContractsTabContainer = {
          [`${formId}`]: {
            tabFocus: 0,
            tabs: initialTabs
          }
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updateTabContainers(initialContractsTabContainer));
      } else {
        setPingTestTabs(tabContainer.tabs);
        setTabFocus(tabContainer.tabFocus);
      }
    }
  }, [tabContainer, result, rawTestResult, formId]);

  const [scroll] = useState<number>(parseInt(localStorage.getItem('PingTestModalScrollPosition') || "0"));
  useEffect(() => {
    setTimeout(() => {
      const pingTestModalScrollable = document.querySelector("#ModalScrollController");      
      pingTestModalScrollable?.scrollTo({top: scroll});      
    }, 300);    
  }, [scroll]);
  
  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {        
    const pingTestModalScrollable = document.querySelector("#ModalScrollController");
    localStorage.setItem('PingTestModalScrollPosition', `${pingTestModalScrollable?.scrollTop+200}`);  
    
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );        
  };

  return (
    <>
      { pingTestTabs && 
          <>
              <Typography variant="h6" component="h6">{`${formData?.postedTestPing?.params?.Route} [${formData?.postedTestPing?.params?.TestLeadId}] Result`}</Typography>
              <TabsContainer
                  tabs={pingTestTabs}
                  tabFocus={tabFocus}
                  onChange={handleTabChangeFocus}
                  tabsContainerId={formId}
              />
          </>
      }
    </>
  );
};

export default connect((state: RootState, ownProps: TestPingResultProps) => {    
    const formData = state.formsSection.formContainers[ownProps.formId].formData || null;
    const result = parseTest(formData?.postedTestPing);
    return {
        formData,
        result,
        tabContainer: state.tabsSection.tabContainers[ownProps.formId] || null,
    }
})(TestPingResult);

const useStyles = makeStyles(() =>
  createStyles({
    responseTitle: {
      //fontWeight: "bold",
    },
    tabContent: {
      padding: "14px",
    },
    pingResponseContainer: {
      padding: "10px 30px !important",
    },
    CodeWrapper: {
      maxHeight: "120px",
      width: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      padding: "5px",
      fontFamily: "monospace",
      boxShadow: "none",
      minHeight: "21px",
      wordBreak: "break-all",
    },
  })
);
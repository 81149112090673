import { Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { ContentLoading, LDPUIDataTable } from "../../components";
import { RootState } from "../../state";
import LaunchIcon from "@material-ui/icons/Launch";
import { deleteTab, updateTabContainers } from "../../state/tabsReducer";
import { useLazyQuery } from "@apollo/react-hooks";
import { HistoryView } from "../../views/history/HistoryView";
import { GetContractAffiliatePriceHistory, GetContractAffiliatePriceHistoryVariables, GetContractAffiliatePriceHistory_LDPConfigQueryGroup_GetContractAffiliatePriceHistory } from "../../common/models/types/GetContractAffiliatePriceHistory";
import { GET_CONTRACT_AFFILIATE_PRICE_HISTORY } from "../../common/models/contractAffiliatePrice";

interface ContractAffiliatePriceHistoryProps {
    dispatch: Function;
    tabContainer: string;
    tabSections: RootState['tabsSection'];
    contractId: number;
    contractAffiliatePriceId: string;
    affiliateId: number;
}

interface ConfigProps {
    ModifiedDate?: String;
    UserId?: String;
    Data?: GetContractAffiliatePriceHistory_LDPConfigQueryGroup_GetContractAffiliatePriceHistory[];
}

const ContractAffiliatePriceHistory = ({
    dispatch,
    tabContainer: TAB_CONTAINER,
    tabSections,
    contractId,
    affiliateId,
    contractAffiliatePriceId,
}: ContractAffiliatePriceHistoryProps) => {

    const [getContractAffiliatePriceHistory, { data, error, loading, called, refetch }] = 
        useLazyQuery<GetContractAffiliatePriceHistory, GetContractAffiliatePriceHistoryVariables>(
            GET_CONTRACT_AFFILIATE_PRICE_HISTORY, { fetchPolicy: 'network-only' });


    const [config, setConfig] = useState<Array<ConfigProps>>([]);

    const handleCloseTab = (tabId: string) => {
        dispatch && dispatch(
            deleteTab({
                tabContainer: TAB_CONTAINER,
                tabId: tabId,
            })
        );
    };

    const handleAddTab = (title: string, content: JSX.Element, tabId: string) => {
        const newTabId = `history-${TAB_CONTAINER}-${tabId}-details`;
        var currentTabs = tabSections.tabContainers[TAB_CONTAINER].tabs;
        const tabFocus = currentTabs.findIndex((obj: { tabId: string; }) => obj.tabId === newTabId);

        if (tabFocus > 0) {
            dispatch && dispatch(updateTabContainers({
                [TAB_CONTAINER]: {
                    tabFocus: tabFocus,
                    tabs: currentTabs
                },
            }));
        }
        else {
            dispatch && dispatch(updateTabContainers({
                [TAB_CONTAINER]: {
                    tabFocus: currentTabs.length,
                    tabs: [
                        ...currentTabs,
                        {
                            tabId: newTabId,
                            title,
                            content,
                            closeTab: () => { handleCloseTab(newTabId) }
                        },
                    ],
                }
            }));
        }
    }

    useEffect(() => {
        if (!data) {
            getContractAffiliatePriceHistory({
                variables: {
                    contractAffiliatePriceId: parseInt(contractAffiliatePriceId),
                }
            })
        }
        else {
            let rows: Array<ConfigProps & { RowId: number }> = [];
            let d: Array<GetContractAffiliatePriceHistory_LDPConfigQueryGroup_GetContractAffiliatePriceHistory> = [];
            data?.LDPConfigQueryGroup?.GetContractAffiliatePriceHistory?.forEach((history, i) => {
                if(history){
                    d.push(history);
                    if (i % 2 === 1) {
                        rows.push({
                            UserId: history?.UserId || '',
                            ModifiedDate: history?.ModifiedDate,
                            Data: d,
                            RowId: rows.length + 1,
                        });
                        d = [];
                    }
                }
                
            });

            if (d.length > 0) {
                rows.push({
                    UserId: d[0]?.UserId || '',
                    ModifiedDate: d[0]?.ModifiedDate,
                    Data: d,
                    RowId: rows.length + 1,
                });
            }

            setConfig(rows);
        }
    }, [data]);

    const columns = [
        {
            name: "RowId",
            label: "Row ID",
            options: {
                filter: true,
            }
        },
        {
            name: "UserId",
            label: "User ID",
            options: {
                filter: true,
            },
        },
        {
            name: "ModifiedDate",
            label: "Changed Date",
            options: {
                filter: false,
                customBodyRender: (value: any, tableMeta: any) => (dateToPreferredTimezone(value))
            },
        },
        {
            name: "RowId",
            label: " ",
            options: {
                viewColumns: false,
                filter: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return (
                        <IconButton onClick={() => handleAddTab(`History:${affiliateId} #${value}`, <HistoryView history={tableMeta.tableData[tableMeta.rowIndex]} />, value)}>
                            <LaunchIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];


    const options = {
        filterType: "checkbox",
        selectableRows: "none",
        responsive: "vertical",
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>&nbsp;</Grid>
            <Grid item xs={12}>
                {!data ? <ContentLoading showTip={true} /> : (
                    <LDPUIDataTable
                        ldpTableId={`contract-${contractId}-affiliate-${contractAffiliatePriceId}-price-history`}
                        restoreFilters={true}
                        title={"Contract Affiliate Price History"}
                        data={config}
                        columns={columns}
                        options={options}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default connect((state: RootState) => ({
    tabSections: state.tabsSection,
}), null)(ContractAffiliatePriceHistory);

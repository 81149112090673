import React from "react";
import InputMask from "react-input-mask";

const TimeInput = ({onChange, onBlur, onClick, onKeyUp, ...props}: any) => {

  
  const handleOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const startNum = e.target.value[0];
    const [hh,mm,ss] = e.target.value.replace(/_/g, '').split(":");
    if((parseInt(startNum) > 1 && parseInt(startNum) < 10)){
      onChange(`0${startNum}:${mm}:${ss}`);
    }
    else onChange(e.target.value);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const blurValue = e.target.value.replace(/_/g, '0'); 
    onChange(blurValue);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if((parseInt(e.key) > 1 && parseInt(e.key) < 10 && e.currentTarget.selectionStart === 1)){
      e.currentTarget.setSelectionRange(3, 3);
    }
    else onChange(e.currentTarget.value);
  };

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.setSelectionRange(0, 0);
  };

  return <InputMask mask="99:99:99" placeholder="HH:MM:SS" onChange={handleOnChange} onBlur={handleOnBlur} onKeyUp={handleKeyUp} onClick={handleInputClick} {...props} />;
};

export default TimeInput;

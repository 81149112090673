import React, { useEffect, useState } from 'react'
import { TabProps, TabsContainer } from '../../components/tabs';
import { connect } from 'react-redux';
import { RootState } from '../../state';
import { updateTabContainers } from '../../state/tabsReducer';
import { CollectiveAffiliatePriceData } from './contractAffiliatePrice';
import ContractAffiliatePricePerAffiliate from './contractAffiliatePriceHistoryPerAffiliate';


interface ContractAffiliatePriceHistoryTabContainerProps {
  /* data: CollectiveAffiliatePriceData; */
  contractId: number;
  price: number;

  dispatch: Function;
  tabSections: RootState['tabsSection'];
  formContainers: RootState['formsSection']['formContainers'];
}

const ContractAffiliatePriceHistoryTabContainer = ({ contractId, price, tabSections, dispatch }: ContractAffiliatePriceHistoryTabContainerProps) => {
  const TAB_CONTAINER = `contract-${contractId}-affiliate-price-history-${price.toFixed(2)}`;

  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabs, setTabs] = useState<TabProps[]>([]);
  const [tabsInitialized, setTabsInitialized] = useState(false);

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    dispatch && dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (!tabSections?.tabContainers[TAB_CONTAINER]) {
      let initialTabContainerFocus = 0;

      let initialTabs: TabProps[] = [
        {
          title: "Details",
          tabId: `${TAB_CONTAINER}-main`,
          content: <ContractAffiliatePricePerAffiliate contractId={contractId} tabContainer={TAB_CONTAINER} />,
        },
      ];

      const initialTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch && dispatch(updateTabContainers(initialTabContainer));
    } else {
      if (tabSections.tabContainers[TAB_CONTAINER]) {
        setTabs(tabSections.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(tabSections.tabContainers[TAB_CONTAINER].tabFocus);
        setTabsInitialized(true);
      }
    }
  }, [tabSections]);

  return (
    <div>
      <TabsContainer
        tabs={tabs}
        onCloseTab={setTabs}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
    </div>
  )
}

const ContractAffiliatePriceHistoryTabContainerWrapper = connect((state: RootState) => ({
  tabSections: state.tabsSection,
  formContainers: state.formsSection.formContainers,
}), null)(ContractAffiliatePriceHistoryTabContainer);

export default ContractAffiliatePriceHistoryTabContainerWrapper;

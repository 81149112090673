import gql from "graphql-tag";

export const GET_TRANSFORM_CODES = gql`
query GetTransformCodes($where: String){
    LDPConfigQueryGroup {
      TransformCode(where: $where, limit: 5000) {
        BuyerId
        SubVerticalName
        VerticalName
        TransformCodeId
        TransformCodeName
        TransformCode1
        IsActive
        MethodName
        CreatedDate
        ModifiedDate
        UserId
        Map
        InputFieldType
        OutputFieldType
        FieldName
        TargetFieldName
      }
    }
  }
  `;


export const SAVE_TRANSFORM_CODES = gql`
mutation SaveTransformCode($transformCodeInput: TransformCodeInputType!){
    LDPConfigMutationGroup {
      SaveTransformCode(TransformCodeInput: $transformCodeInput)
    }
  }
  `;
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { SAVE_CONTRACT_REQUEST_HEADER } from "../../common/models/contractRequestHeader";
import { ContractRequestHeaderInputType } from "../../types/graphql-global-types";
import { GetContracts_LDPConfigQueryGroup_Contract } from "../../common/models/types/GetContracts";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import { MenuInputAdornment } from "../../components/menuInputAdornment";

interface ContractFormModalProps {
  data: ContractRequestHeaderInputType | null;
  contractId: any;
  action: string;
  close: Function;
  refetch: Function;
}

const formError = {
  Value: {
    required: {
      value: true,
      message: "Value is required.",
    },
  },
  Key: {
    required: {
      value: true,
      message: "Key is required.",
    },
  },
};

export const ContractRequestHeaderFormModal = ({
  action,
  contractId,
  data,
  close,
  refetch,
}: ContractFormModalProps) => {
  const { register, handleSubmit, setValue, watch, errors } = useForm<
    ContractRequestHeaderInputType
  >();
  const classes = useStyles();
  const pageTitle =
    action == "create" ? "Add New Request Header" : "Edit Request Header";
  const [saveContract] = useMutation(SAVE_CONTRACT_REQUEST_HEADER);
  const onSubmit = (contractRequestHeader: ContractRequestHeaderInputType) => {
    saveContract({
      variables: { contractRequestHeader: [{ ...contractRequestHeader, UserId: '', }] },
    }).then((response: any) => {
      if (response.data.LDPConfigMutationGroup.SaveContractRequestHeader) {
        if (contractRequestHeader?.ContractRequestHeaderId > 0)
          toast.success("Request Header updated successfully.");
        else toast.success("Request Header created successfully.");
      }
      refetch();
      close();
    }).catch((error) => {
      console.log('saveContract', error);
    });
  };

  const [headerKey, setHeaderKey] = useState(data?.Key ?? "");
  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractRequestHeaderId"
              defaultValue={data?.ContractRequestHeaderId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? contractId}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
            <TextField
              inputRef={register(formError.Key)}
              error={errors.Key && true}
              helperText={errors.Key && errors.Key?.message}
              name="Key"
              label="Header Type"
              defaultValue={data?.Key ?? ""}
              variant="outlined"
              value={headerKey}
              onChange={(e)=>{
                if(typeof e.target.value === "string"){
                  setValue("Key", e.target.value);
                  setHeaderKey(e.target.value);
                }
              }}
              InputProps={{
                endAdornment:
                  <MenuInputAdornment options={[
                    "Accept",
                    "Authorization",
                    "Content-Type",
                    "x-api-key"
                  ]} onSelect={(value: any) => {
                    if (typeof value === 'string') {
                      setValue("Key", value);
                      setHeaderKey(value);
                    }
                  }} />
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputRef={register}
              name="Value"
              label="Value"
              defaultValue={data?.Value || ""}
              variant="outlined"
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsPing ?? true}
                  name="IsPing"
                  color="primary"
                />
              }
              label="IsPing"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

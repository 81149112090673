import gql from "graphql-tag";

export const PING_TEST_CONTRACT = gql`
  query PingTest($pingInput: TestPingInputType!) {
    LDPConfigQueryGroup {
      PingTest(TestPingInput: $pingInput){
        errorMessage
        pingResponse {
          authHeaderSchema
          authHeaderValue
          contentEncoding
          contractId
          createdDate
          errorMessage
          headerValues
          httpVerb
          ingestQueueId
          ingestQueueStateId
          isComplete
          isSuccess
          isUrlEncodedRequest
          leadDataId
          leadUuid
          minimumPrice
          modifiedDate
          parsedTokens
          priceAdjustmentPercentage
          priceOffered
          rawPriceOffered
          receiveDate
          requestPayload
          requestUrl
          responseDispositionId
          responsePayload
          responseStatusCode
          tableId
          timeoutSeconds
          transmitDate
        }
        postResponse{
          authHeaderSchema
          authHeaderValue
          contentEncoding
          contractId
          createdDate
          errorMessage
          headerValues
          httpVerb
          ingestQueueId
          ingestQueueStateId
          isComplete
          isSuccess
          isUrlEncodedRequest
          leadDataId
          leadUuid
          minimumPrice
          modifiedDate
          parsedTokens
          priceAdjustmentPercentage
          priceOffered
          rawPriceOffered
          receiveDate
          requestPayload
          requestUrl
          responseDispositionId
          responsePayload
          responseStatusCode
          tableId
          timeoutSeconds
          transmitDate
        }
        requestData
      }
    }
  }
`;

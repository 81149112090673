import gql from "graphql-tag";

export const GET_CONTRACT_CAPACITY = gql`
    query GetContractCapacity($where: String){
        LDPConfigQueryGroup {
            ContractCapacity(limit:9999, where: $where) {
                ContractCapacityId
                ContractId
                CreatedDate
                EndTime
                EndTimeSecondsPastMidnight
                IsActive
                IsPing
                MaxCapacity
                ModifiedDate
                Notes
                Price
                ScheduleDay
                StartTime
                StartTimeSecondsPastMidnight
                UserId
            }
        }
    }
`;  

export const SAVE_CONTRACT_CAPACITY = gql`
    mutation SaveContractCapacity($contractCapacityInput: [ContractCapacityInputType]!){
        LDPConfigMutationGroup {
            SaveContractCapacity(ContractCapacityInput : $contractCapacityInput)
        }
    }
`; 

export const SAVE_BULK_CONTRACT_CAPACITY = gql`
    mutation RootMutation($contractCapacityInput: ContractCapacityBulkInputType!) {
        LDPConfigMutationGroup {
            SaveBulkContractCapacity(ContractCapacityInput: $contractCapacityInput)
        }
    }
`;

export const UPDATE_MULTI_CONTRACT_CAPACITY_PRICE = gql`
    mutation UpdateMultiContractCapacityPrice($contractCapacityPriceInput: ProcMergeContractCapacityPriceInput!){
        LDPConfigMutationGroup {
            UpdateMultiContractCapacityPrice(ContractCapacityPriceInput : $contractCapacityPriceInput)
        }
    }
`; 

export const DELETE_CONTRACT_CAPACITY_BY_IDS = gql`
    mutation LDPConfigMutationGroup($contractCapacityIds: [ID]!, $userId: String) {
        LDPConfigMutationGroup {
            DeleteContractCapacity(ContractCapacityIds: $contractCapacityIds, UserId: $userId)
        }
    }
`; 

import gql from "graphql-tag";

export const GET_TCPA_TYPE = gql`
  query GetTcpaType($isActive: Boolean) {
    LDPConfigQueryGroup {
      GetTcpaType(IsActive: $isActive) {
        CreatedDate
        IsActive
        ModifiedDate
        TcpaType
        TcpaTypeId
        UserId
      }
    }
  }
`;

export const SAVE_TCPA_TYPE = gql`
  mutation SaveTcpaType($tcpaTypeData: TcpaTypeTypeInput!) {
    LDPConfigMutationGroup {
      SaveTcpaType(TcpaTypeData: $tcpaTypeData) {
        ActionTaken
        TcpaTypeId
      }
    }
  }
`;
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export const ChipList = ({ title, list }: any) => {
  const classes = useStyles();
  return (
    <Box p={3}>
      <Typography>{title}</Typography>
      <Box>
        {!list?.length && (
          <Chip label={"None"} className={classes.chip} color="primary" />
        )}
        {list.map((data: string, index: number) => (
          <Chip
            key={index}
            label={data}
            className={classes.chip}
            color="primary"
          />
        ))}
      </Box>
    </Box>
  );
};

import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle, FileCopy } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_BUYER_FILTERS } from "../../common/models/buyerFilters";
import { GET_CONTRACT_FILTERS } from "../../common/models/contractFilters";
import { GET_CONTRACTS } from "../../common/models/contracts";
import {
  GetBuyerFilters,
  GetBuyerFiltersVariables,
} from "../../common/models/types/GetBuyerFilters";
import { GetContractFilters } from "../../common/models/types/GetContractFilters";
import { GetContracts } from "../../common/models/types/GetContracts";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { queryLoad, StatusIcon, useModal } from "../../components";
import { ChipList } from "../../components/chipList";
import { booleanRadioFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { EllipsisTooltip, useEllipsisStyles } from "../../components/tooltip";
import { RootState } from "../../state";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import { dockForm, openForm, resetForm } from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractFilterCloneModal } from "./contractCloneFilterModal";
import { ContractFilterFormModal } from "./contractFilterModal";

//Update props types once view requirements are finalized
const ContractFilters = ({ contractId, dispatch, contractForms }: any) => {
  const [
    getContractFilters,
    { data, loading, error, refetch },
  ] = useLazyQuery<GetContractFilters>(GET_CONTRACT_FILTERS, {
    fetchPolicy: "cache-and-network",
  });

  const classes = useEllipsisStyles();

  const LDP_TABLE_ID = `contract-filter-list-${contractId}`;

  useEffect(() => {
    getContractFilters({ variables: { where: `ContractId = ${contractId}` } });
  }, []);

  const [
    getContract,
    { data: contractData, loading: contractLoading },
  ] = useLazyQuery<GetContracts>(GET_CONTRACTS);

  const [
    getBuyerFilterList,
    { data: buyerFilterData, loading: buyerFilterLoading },
  ] = useLazyQuery<GetBuyerFilters, GetBuyerFiltersVariables>(
    GET_BUYER_FILTERS,
    { fetchPolicy: "network-only" }
  );

  const [chipList, setChipList] = useState<any>([]);

  useEffect(() => {
    getContract({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (contractData) {
      getBuyerFilterList({
        variables: {
          buyerId: contractData.LDPConfigQueryGroup?.Contract[0]?.BuyerId,
        },
      });
    }
  }, [contractData]);

  useEffect(() => {
    if (buyerFilterData) {
      if (buyerFilterData.LDPConfigQueryGroup?.GetBuyerFilters) {
        setChipList(
          buyerFilterData.LDPConfigQueryGroup?.GetBuyerFilters.filter(
            (buyerFilter) => buyerFilter?.IsActive === true
          ).map((buyerFilter) => buyerFilter?.BuyerFilterName)
        );
      }
    }
  }, [buyerFilterData]);

  const columnsMUI = [
    {
      name: "ContractFilterId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">Edit</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    if (
                      data &&
                      data?.LDPConfigQueryGroup &&
                      data?.LDPConfigQueryGroup?.ContractFilter
                    ) {
                      const contractFilter = data?.LDPConfigQueryGroup?.ContractFilter.filter(
                        (item) => item?.ContractFilterId === value
                      ).pop();

                      if (contractFilter) {
                        openModal({
                          title: "Edit Contract Filter",
                          icon: <EditIcon />,
                          iconColor: "orange",
                          content: (
                            <ContractFilterFormModal
                              action="edit"
                              data={contractFilter}
                              filters={data?.LDPConfigQueryGroup?.ContractFilter}
                              close={closeModal}
                              refetch={refetch}
                            />
                          ),
                        });
                      }
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    const HISTORY_TAB_ID = `contract-${contractId}-contract-filter-history-${value}`;

                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Contract Filter #${value} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="ContractFilter"
                          ConfigId={value}
                          TabContainerId={HISTORY_TAB_ID}
                        />
                      ),
                      formData: {
                        ConfigName: "ContractFilter",
                        ConfigId: value,
                        ...tableMeta.tableData[tableMeta.rowIndex],
                      },
                      formProps: {
                        closeTab: () =>
                          dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      },
                    };

                    dispatch(
                      updateContractDetailForms({
                        contractId: `contract-${contractId}`,
                        formIds: [HISTORY_TAB_ID],
                      })
                    );
                    dispatch(
                      contractForms.formContainers[HISTORY_TAB_ID] &&
                        contractForms.formContainers[HISTORY_TAB_ID]
                          .isLastOpenedDocked
                        ? dockForm(formProps)
                        : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "ContractFilterId",
      label: "FilterId",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "ContractFilterName",
      label: "Filter Name",
      options: {
        filter: false,
      },
    },
    {
      name: "FieldName",
      label: "FieldName",
      options: {
        filter: false,
      },
    },
    {
      name: "Operation",
      label: "Operation",
      options: {
        filter: false,
      },
    },
    {
      name: "Value",
      label: "Value",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span
              className={`${classes.ellipsisColumn} ${classes.ellipsisColumnFilterValue}`}
            >
              {value}
            </span>
          </EllipsisTooltip>
        ),
      },
    },
    {
      name: "Delimiter",
      label: "Delimiter",
      options: {},
    },
    {
      name: "ExternalProcedureName",
      label: "Ext Proc Name",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: "ProcedureParameterName",
      label: "Proc Param Name",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: [],
      },
    },
    {
      name: "IsDecimal",
      label: "IsDecimal",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        display: false,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsDecimal: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "IsList",
      label: "IsList",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
        display: false,
      },
    },
    {
      name: "IsNumeric",
      label: "IsNumeric",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        display: false,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsNumeric: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "IsPing",
      label: "IsPing",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {},
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  const { Modal, closeModal, openModal, setContent } = useModal();

  return (
    <>
      {queryLoad([!!loading], [error]) || (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ChipList title={"Buyer Filter"} list={chipList} />
          </Grid>
          <Grid item xs={6}>
            <Box p={1}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<FileCopy />}
                    onClick={() => {
                      openModal({
                        title: "Clone Contract Filter",
                        icon: <FileCopy />,
                        iconColor: "orange",
                        content: (
                          <ContractFilterCloneModal
                            contractId={contractId}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    Clone Filter
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<AddCircle />}
                    onClick={() => {
                      openModal({
                        title: "Add New Contract Filter",
                        icon: <AddCircle />,
                        iconColor: "orange",
                        content: (
                          <ContractFilterFormModal
                            action={"create"}
                            data={{
                              ContractId: contractId,
                              ContractFilterId: "-1",
                            }}
                            filters={data?.LDPConfigQueryGroup?.ContractFilter}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    Add Filter
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LDPUIDataTable
              ldpTableId={LDP_TABLE_ID}
              restoreFilters={true}
              title={""}
              data={data?.LDPConfigQueryGroup?.ContractFilter}
              columns={columnsMUI}
              options={options}
            />
            <Modal />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    contractRequestBodyRecordSaved:
      state.contractRequestSection.contractRequestBodyRecordSaved,
    contractForms: state.formsSection,
  }),
  null
)(ContractFilters);

import gql from "graphql-tag";

export const GET_CONTRACT_AFFILIATE_PRICE = gql`
  query GetContractAffiliatePrice(
    $where: String
    $limit: Int
    $page: Int
    $orderby: String
    $count: Boolean
  ) {
    LDPConfigQueryGroup {
      ContractAffiliatePrice(
        where: $where
        limit: $limit
        page: $page
        orderby: $orderby
        count: $count
      ) {
        AffiliateId
        ContractAffiliatePriceId
        ContractId
        CreatedDate
        IsActive
        ModifiedDate
        Notes
        Price
        UserId
      }
    }
  }
`;

export const SAVE_CONTRACT_AFFILIATE_PRICE = gql`
  mutation SaveContractAffiliatePrice(
    $affiliatePriceData: MergeContractAffiliatePriceInputType!
    $userId: String
  ) {
    LDPConfigMutationGroup {
      SaveContractAffiliatePrice(AffiliatePriceData: $affiliatePriceData, UserId: $userId) {
        ActionTaken
        ContractAffiliatePriceId
      }
    }
  }
`;

export const DEL_CONTRACT_AFFILIATE_PRICE = gql`
  mutation DelContractAffiliatePrice(
    $contractId: Long!
    $affiliateIDs: [Long!]
    $userId: String
  ) {
    LDPConfigMutationGroup {
      DelContractAffiliatePrice(
        ContractID: $contractId
        AffiliateIDs: $affiliateIDs
        UserId: $userId
      ) {
        ActionTaken
        ContractAffiliatePriceId
      }
    }
  }
`;

export const GET_CONTRACT_AFFILIATE_PRICE_HISTORY = gql`
query GetContractAffiliatePriceHistory($contractAffiliatePriceId: Long) {
  LDPConfigQueryGroup {
    GetContractAffiliatePriceHistory(ContractAffiliatePriceId: $contractAffiliatePriceId) {
      AffiliateId
      ContractAffiliatePriceId
      ContractId
      CreatedDate
      HistoryAction
      HistoryDate
      IsActive
      ModifiedDate
      Notes
      Price
      TransactionId
      UserId
    }
  }
}
`;

import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { ContentLoading, LDPUIDataTable } from "../../components";
import { RootState } from "../../state";
import LaunchIcon from "@material-ui/icons/Launch";
import { deleteTab, updateTabContainers } from "../../state/tabsReducer";
import ContractAffiliatePriceHistory from './contractAffiliatePriceHistory';
import { CollectiveAffiliatePriceData } from './contractAffiliatePrice';

const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
  if (rowData) {
    let newJson: any = {};
    muiColumns.map((column: any, key: number) => {
      if (column.name) newJson[column.name] = rowData[key];
    });
    return newJson;
  } else return null;
};

interface ContractAffiliatePricePerAffiliateProps {
  dispatch: Function;
  tabSections: RootState['tabsSection'];
  generalDataStore: RootState['allPurposeDataState'],

  /* data: CollectiveAffiliatePriceData["Affiliates"]; */
  contractId: number;
  tabContainer: string;
}

const ContractAffiliatePricePerAffiliate = ({
  dispatch,
  /* data, */
  contractId,
  tabContainer: TAB_CONTAINER,
  tabSections,
  generalDataStore,
}: ContractAffiliatePricePerAffiliateProps) => {

  const historyDataId = `Contract#${contractId}_AffiliatePriceData`;

  const handleCloseTab = (tabId: string) => {
    dispatch && dispatch(
      deleteTab({
        tabContainer: TAB_CONTAINER,
        tabId: tabId,
      })
    );
  };
  
  const handleAddTab = (title: string, content: JSX.Element, tabId: string) => {
    const newTabId = `history-${TAB_CONTAINER}-${tabId}`;
    var currentTabs = tabSections.tabContainers[TAB_CONTAINER].tabs;
    const tabFocus = currentTabs.findIndex((obj: { tabId: string; }) => obj.tabId === newTabId);

    if(tabFocus > 0){
        dispatch && dispatch(updateTabContainers({
          [TAB_CONTAINER]: {
            tabFocus: tabFocus,
            tabs: currentTabs
          },
        }));
      }
    else{
      dispatch && dispatch(updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: currentTabs.length,
          tabs: [
            ...currentTabs,
            {
              tabId: newTabId,
              title,
              content,
              closeTab: ()=>{handleCloseTab(newTabId)}
            },
          ],
        }
      }));
    }
  }

  const columnsMUI = [
    {
      name: "AffiliateId",
      label: "AffiliateId",
      options: {
        viewColumns: true,
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
      }
    },
    {
      name: "ContractAffiliatePriceId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const rowData = mapRowDataToColumns(
            columnsMUI,
            tableMeta.rowData
          )
          return (
            <>
              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">View Affiliate Price History</Typography>
                </React.Fragment>
              }>
                <IconButton onClick={() => {  
                  handleAddTab(
                    `Affiliate:${tableMeta.rowData[0]}`, 
                    <ContractAffiliatePriceHistory contractId={contractId} tabContainer={TAB_CONTAINER} contractAffiliatePriceId={value} affiliateId={rowData.AffiliateId}/>, 
                    `${TAB_CONTAINER}-${value}`
                  )
                }}>
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </>

          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>&nbsp;</Grid>
      <Grid item xs={12}>
        {!generalDataStore[historyDataId] ? <ContentLoading showTip={true} /> : (
          <LDPUIDataTable
            ldpTableId={`contract-${contractId}-affiliate-price-list-breakdown`}
            restoreFilters={true}
            title={"Contract Affiliate Price"}
            data={generalDataStore[historyDataId]}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default connect((state: RootState) => ({
  tabSections: state.tabsSection,
  generalDataStore: state.allPurposeDataState,
}), null)(ContractAffiliatePricePerAffiliate);

import React from "react"
import { InputAdornment, Tooltip } from "@material-ui/core"
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface HelpTipProps {
    title: string;
    size?: string;
    isInputAdornment?: boolean;
    position?: string;
}

export const HelpTip = ({title, size, isInputAdornment, position}: HelpTipProps) => {
    
    const fontSize = size ? size : "inherit";
    const pos = position ? position : "start"

    const render = (tooltip: any) => {
        if (isInputAdornment) {
            return (
                <InputAdornment position={pos}>
                    {tooltip}
                </InputAdornment>    
            );
        }

        return tooltip;
    }

    return render(
        <Tooltip title={title}>
            <HelpOutlineIcon fontSize={fontSize} />
        </Tooltip>
    );
}
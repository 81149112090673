import React, { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { CLONE_CONTRACT } from "../../common/models/contracts";
import { CloneContractInputType } from "../../types/graphql-global-types";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import CopyIcon from "@material-ui/icons/FileCopyRounded";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { GetContracts_LDPConfigQueryGroup_Contract } from "../../common/models/types/GetContracts";
import { GET_CONTRACTS_SIMPLE } from "../../common/models/contracts";
import {
  GetContractsSimple
} from "../../common/models/types/GetContractsSimple";

interface ContractCloneModalProps {
  data?: Array<GetContracts_LDPConfigQueryGroup_Contract> | null;
  close: Function;
  refetch: Function;
}
interface MappedSelectField {
  id: number;
  label: string;
}

const formError = {
  ContractName: {
    required: {
      value: true,
      message: "New Contract Name is required.",
    },
  },
  ContractId: {
    required: {
      value: true,
      message: "Reference Contract is required.",
    },
  }
};

export const ContractCloneModal = ({
  data,
  close,
  refetch,
}: ContractCloneModalProps) => {

  const [
    getMatchContracts,
    { data: matchedContracts, error: matchContractsError, loading: matchContractsLoading },
  ] = useLazyQuery<GetContractsSimple>(GET_CONTRACTS_SIMPLE, {
    onCompleted: (data) => {
      /** if no same contract name is found, lets submit our form data */
      if(!data.LDPConfigQueryGroup?.Contract || data.LDPConfigQueryGroup?.Contract.length === 0){
        console.log("form submitted for cloning!");
        let contractData = {
          ...formDataInput,
          ContractId: mappedContracts?.find(
            (contract: MappedSelectField) => contract?.label === formDataInput.ContractId
          )?.id,
        };
    
        setLoading(true);
        cloneContract({ variables: { contractParams: { ...contractData, UserId: '' } } }).then((response: any) => {
          toast.success("Contract cloned successfully.");
          refetch();
          close();
        }).catch(error => {
          toast.error(error);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        //bring up modal or error
        setError("NewContractName", {
          type: 'manual',
          message: 'Contract name already exists.'
        });
      }
    }
  });

  const { register, handleSubmit, watch, setValue, setError, errors, control } = useForm<CloneContractInputType>();
  const [mappedContracts, setMappedContracts] = useState<MappedSelectField[]>();
  const [selectedContracts, setSelectedContracts] = useState<MappedSelectField | null>(
    null
  );
  const classes = useStyles();
  const pageTitle = "Clone Existing Contract";
  const [cloneContract] = useMutation(CLONE_CONTRACT);
  const [loading, setLoading] = useState(false);
  const [formDataInput, setFormDataInput] = useState<CloneContractInputType | null>(null);

  const onSubmit = (dataInput: CloneContractInputType) => {
    setFormDataInput(dataInput);
  };

  /** ensures that we have form data available when we check for duplicate names */
  useEffect(() => {
    if(formDataInput){
      getMatchContracts({
        variables: {
          where: `ContractName = "${formDataInput.NewContractName.trim()}"`
        }});
    }
  }, [formDataInput]);

  useEffect(() => {
    if (data) {
      setMappedContracts(
        data?.map(contract => {
          return { id: contract?.ContractId, label: `${contract.ContractId} - ${contract.ContractName}` };
        })
      );
    }
  }, [data]);

  return (
    <Paper className={classes.container}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="contract-id"
              options={mappedContracts || []}
              getOptionLabel={option => option.label}
              onChange={(event: any, newValue: MappedSelectField | null) => {
                setSelectedContracts(newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={register(formError.ContractId)}
                  helperText={errors.ContractId && errors.ContractId?.message}
                  error={!!errors.ContractId}
                  name="ContractId"
                  label="Select Contract To Copy"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(formError.ContractName)}
              error={errors.NewContractName && true}
              helperText={errors.NewContractName && errors.NewContractName?.message}
              name="NewContractName"
              label="New Contract Name"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractCapacity"
                  color="primary"
                />
              }
              label="Clone Capacity"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractFilter"
                  color="primary"
                />
              }
              label="Clone Filters"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractRequestHeader"
                  color="primary"
                />
              }
              label="Clone Request Header"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractRequestSchema"
                  color="primary"
                />
              }
              label="Clone Request Body"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractResponseParsing"
                  color="primary"
                />
              }
              label="Clone Response Mapping"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  name="CloneContractResponseToken"
                  color="primary"
                />
              }
              label="Clone Token"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={loading}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<CopyIcon />}
            >
              Clone
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      width: "100%",
      marginBottom: "20px"
    },
  })
);

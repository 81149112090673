import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Box, Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import HistoryIcon from "@material-ui/icons/History";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_CONTRACT_AFFILIATE_PRICE, DEL_CONTRACT_AFFILIATE_PRICE } from "../../common/models/contractAffiliatePrice";
import { GetContractAffiliatePrice, GetContractAffiliatePriceVariables } from "../../common/models/types/GetContractAffiliatePrice";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { queryLoad, StatusIcon, useModal, useConfirmation } from "../../components";
import { booleanRadioFilter, buyerFilter, numberColumnFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractAffiliatePriceFormModal } from "./contractAffiliatePriceFormModal"; import EditIcon from '@material-ui/icons/Edit';
import { DelContractAffiliatePrice, DelContractAffiliatePriceVariables } from "../../common/models/types/DelContractAffiliatePrice";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import ContractAffiliatePriceHistoryTabContainer from './contractAffiliatePriceHistoryTabContainer';
import { updateRecordData } from "../../state/generalStateReducer";

interface ContractAffiliatePriceProps {
  contractId: number;
  contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps;
}

export interface CollectiveAffiliatePriceData {
  ModifiedDate: string;
  Affiliates: {
    ContractAffiliatePriceId: number | null;
    AffiliateId: number;
    UserId?: string | null;
    Notes?: string | null;
    ModifiedDate: string;
  }[];
  AffiliateId: string;
  Price: number;
  IsActive: boolean;
  UserId?: string | null;
  Notes?: string | null;
}

const mapRowDataToColumns = (muiColumns: any, rowData: any) => {
  if (rowData) {
    let newJson: any = {};
    muiColumns.map((column: any, key: number) => {
      if (column.name) newJson[column.name] = rowData[key];
    });
    return newJson;
  } else return null;
};

//Update props types once view requirements are finalized
const ContractAffiliatePrice = ({
  contractId,
  dispatch,
  contractForms,
}: ContractAffiliatePriceProps) => {
  const LDP_CONTRACT_AFF_PRICE_TABLE_ID = `contract-affiliate-price-${contractId}`;

  const [
    getContractAffiliatePrice,
    {
      data: priceData,
      loading: priceLoading,
      error: priceError,
      refetch: priceRefetch,
    },
  ] = useLazyQuery<GetContractAffiliatePrice, GetContractAffiliatePriceVariables>(GET_CONTRACT_AFFILIATE_PRICE, {
    fetchPolicy: "network-only",
  });

  const { Modal: Confirmation, closeModal: closeConfirmation, useModal: setConfirmation } = useConfirmation();

  const [priceListData, setPriceListData] = useState<CollectiveAffiliatePriceData[]>([]);


  useEffect(() => {
    getContractAffiliatePrice({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (priceData) {
      const byPrice: {
        [price: number]: CollectiveAffiliatePriceData;
      } = {};

      for (const affiliatePrice of (priceData.LDPConfigQueryGroup?.ContractAffiliatePrice || [])) {
        if (!byPrice[affiliatePrice?.Price]) {
          byPrice[affiliatePrice?.Price] = {
            ModifiedDate: affiliatePrice?.ModifiedDate,
            Affiliates: [{ 
              ContractAffiliatePriceId: affiliatePrice?.ContractAffiliatePriceId, 
              UserId: affiliatePrice?.UserId,
              Notes: affiliatePrice?.Notes,
              ModifiedDate: affiliatePrice?.ModifiedDate,
              // @ts-ignore
              AffiliateId: affiliatePrice?.AffiliateId }],
            AffiliateId: "",
            Price: affiliatePrice?.Price,
            IsActive: true,
            UserId: affiliatePrice?.UserId,
            Notes: affiliatePrice?.Notes,
          }
        } else {
          byPrice[affiliatePrice?.Price].Affiliates.push(
            { 
              ContractAffiliatePriceId: affiliatePrice?.ContractAffiliatePriceId, 
              UserId: affiliatePrice?.UserId,
              Notes: affiliatePrice?.Notes,
              ModifiedDate: affiliatePrice?.ModifiedDate,
              // @ts-ignore
              AffiliateId: affiliatePrice?.AffiliateId }
          );

          if (affiliatePrice?.ModifiedDate > byPrice[affiliatePrice?.Price].ModifiedDate) {
            byPrice[affiliatePrice?.Price].ModifiedDate = affiliatePrice?.ModifiedDate;
            byPrice[affiliatePrice?.Price].UserId = affiliatePrice?.UserId;
            byPrice[affiliatePrice?.Price].Notes = affiliatePrice?.Notes || byPrice[affiliatePrice?.Price].Notes;
          }
        }

        byPrice[affiliatePrice?.Price].AffiliateId = byPrice[affiliatePrice?.Price].Affiliates.map(a => a.AffiliateId).sort().join(", ")
      }

      setPriceListData(Object.values(byPrice));

    } else if (priceError) {
      setPriceListData([]);

    }

  }, [priceData, priceError]);

  const { Modal, closeModal, openModal } = useModal();

  const AffiliatePriceColumnsMUI: MUIDataTableColumnDef[] = [
    {
      name: "DataEditCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const data = mapRowDataToColumns(
            AffiliatePriceColumnsMUI,
            tableMeta.rowData
          )
          return (
            <>
              <IconButton
                onClick={() => {
                  if (priceData) openModal({
                    title: `Edit Affiliate Price`,
                    icon: <EditIcon />,
                    iconColor: "orange",
                    content: (
                      <ContractAffiliatePriceFormModal
                        action={"edit"}
                        contractId={contractId}
                        data={data}
                        close={closeModal}
                        refetch={priceRefetch}
                      />
                    ),
                  });
                }}
              >
                <LaunchIcon />
              </IconButton>

              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    const rowData = mapRowDataToColumns(
                      AffiliatePriceColumnsMUI,
                      tableMeta.rowData
                    )

                    // save a data record on redux state
                    dispatch(updateRecordData(`Contract#${contractId}_AffiliatePriceData`, rowData.Affiliates));

                    openModal({
                      title: `Contract #${contractId} Affiliate Price $${rowData.Price.toFixed(2)} History`,
                      icon: <HistoryIcon />,
                      iconColor: "orange",
                      content: (
                        <ContractAffiliatePriceHistoryTabContainer
                          price={rowData.Price}
                          contractId={contractId}
                        />
                      ),
                    });
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "Affiliates",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: "AffiliateId",
      label: "Affiliate ID",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value) => {
          return value;
        },
      },
    },

    {
      name: "Price",
      label: "Price",
      options: {
        customBodyRender: (value: any) => {
          return `${Number(value).toFixed(2)}`;
        },
        ...numberColumnFilter
      },
    },

    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: [],
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {
        ...stringColumnFilter
      },
    },

    /* {
      name: "ContractId",
      label: "Contract ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    }, */

    /* {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
        display: false,
      },
    }, */

    {
      name: "Notes",
      label: "Notes",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "ModifiedDate",
      direction: "desc",
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  if (priceData) openModal({
                    title: `Add New Affiliate Price`,
                    icon: <AddCircle />,
                    iconColor: "orange",
                    content: (
                      <ContractAffiliatePriceFormModal
                        action={"create"}
                        data={null}
                        listData={priceData}
                        contractId={contractId}
                        close={closeModal}
                        refetch={priceRefetch}
                      />
                    ),
                  });
                }}
              >
                Create Affiliate Price
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!priceLoading], [priceError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_CONTRACT_AFF_PRICE_TABLE_ID}
            restoreFilters={true}
            title={"Affiliate Price"}
            data={priceListData}
            columns={AffiliatePriceColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
      <Confirmation />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    contractForms: state.formsSection,
  }),
  null
)(ContractAffiliatePrice);

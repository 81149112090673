import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import {
  Grid, Theme,
  TextField, Button,
  makeStyles,
  createStyles,
} from "@material-ui/core";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { useModal } from "./modal";

interface DataMap {
  [datakey:string] : string;
}

interface ParamPair {
  name : string;
  value: string;
}

interface ValidatorMap {
  [datakey:string] : any;
}

interface KeyValueEditorProps {
  onChange?:(updatedValues:DataMap) => void;
  keyValuePairs: string | DataMap;
} 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: "20vh",
      overflowY: "auto",
      "& .MuiTextField-root": {
        width: "100%",
      },
      marginTop: "10px"
    },
    rootInput: {
      overflowY: "auto",
      "& .MuiTextField-root": {
        width: "100%",
      },
      minHeight: "80px"
    },
    buttonGrid: {
      marginTop: '5px',
      alignItems: 'center'
    }
  })
);

const keyValueEditor = ({ onChange, keyValuePairs }:KeyValueEditorProps, ref:any) => {
  const [paramList, setParamList] = useState<Array<ParamPair>>([]);
  const [inputParameter, setInputParameter] = useState<ParamPair>({ 
    name: '', value: ''
  });

  const { register, handleSubmit, watch, setValue, errors } = useForm<DataMap>();
  const [keyFocus, setKeyFocus] = useState<number | null>(null);

  const classes = useStyles();
  const { Modal, closeModal, openModal } = useModal(undefined, 0.35);

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    setValue: (val:string) => {
      if(keyFocus !== null){
        changeValue(keyFocus, val, true);
      } else {
        setInputParameter({ ...inputParameter, value: val });
      }
      
    }
  }));
  
  const clearDuplicates = (arrList:ParamPair[], lastValueWins:boolean = true) => {
    const processed:any = {};
    const loopList = lastValueWins ? arrList.reverse() : arrList;
    const newParamList:ParamPair[] = [];

    for(let i = 0; i < loopList.length; i++){
      const param = loopList[i];
      if(!processed[param.name]){
        if(lastValueWins){
          newParamList.unshift(param);
        } else {
          newParamList.push(param);
        }

        processed[param.name] = true;
      }
    }

    return newParamList;
  }

  const toDataMap = (list:ParamPair[]) => {
    const result:DataMap = {};

    list.forEach(pair => {
      result[pair.name] = pair.value;
    });

    return result;
  }

  useEffect(() => {
    const newParamList:ParamPair[] = [];

    if(typeof keyValuePairs === "string"){
      if(keyValuePairs.trim() !== ""){
        const items = keyValuePairs.split("&");
        items.forEach(item => {
          const _item = item.trim();
          const _pair = _item.split("=");

          const paramName = _pair[0].replace(/^\?/, '');
          const paramValue = _pair[1] || "";

          newParamList.push({
            name: paramName,
            value: paramValue
          });
        });
      };

      setParamList(clearDuplicates(newParamList));
    } else {
      Object.keys(keyValuePairs).forEach(paramName => {
        newParamList.push({ name: paramName, value: keyValuePairs[paramName] });
      });
      setParamList(newParamList);
    }
  }, [keyValuePairs]);

  const renameParam = (paramIndex:number, newName:string, save:boolean = false) => {
    const newParamList:ParamPair[] = [ ...paramList ];

    newParamList[paramIndex].name = newName;

    if(onChange && save){
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  const changeValue = (paramIndex:number, newValue:string, save:boolean = false) => {
    const newParamList:ParamPair[] = [ ...paramList ];

    newParamList[paramIndex].value = newValue;

    if(onChange && save){
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  const deleteParam = (paramIndex:number) => {
    const newParamList:ParamPair[] = [ ...paramList ];
    newParamList.splice(paramIndex, 1);

    if(onChange){
      onChange(toDataMap(newParamList));
    }
  }

  const addParam = (key:string, value:string, save:boolean = true) => {
    const newParamList:ParamPair[] = [ ...paramList, { name: key, value } ];
    
    if(onChange && save){
      onChange(toDataMap(newParamList));
    } else {
      setParamList(newParamList);
    }
  }

  return (
      <Grid>
        <Grid container spacing={2} className={classes.rootInput}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    label="Paramater Name"
                    value={inputParameter.name}
                    variant="outlined"
                    onFocus={evt => {
                      setKeyFocus(null);
                    }}
                    onChange={(evt:React.ChangeEvent<HTMLInputElement>) => {
                      setInputParameter({ ...inputParameter, name: evt.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    ref={inputRef}
                    label="Paramater Value"
                    value={inputParameter.value}
                    variant="outlined"
                    onFocus={evt => {
                      setKeyFocus(null);
                    }}
                    onChange={(evt:React.ChangeEvent<HTMLInputElement>) => {
                      setInputParameter({ ...inputParameter, value: evt.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} alignItems="center" className={classes.buttonGrid}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    startIcon={<NoteAddIcon />}
                    onClick={() => {
                      const keyName = inputParameter.name;
                      const keyValue = inputParameter.value;

                      if(keyName.trim() === ""){
                        openModal({
                          title: "Parameter Name",
                          content: (
                            <ul>
                              <li>This field is required.</li>
                            </ul>
                          ),
                          yPos: window && ((window.innerHeight * 0.5) - 200)
                        });
                      } else {
                        addParam(keyName, keyValue);
                        setInputParameter({ name: '', value: '' });
                      }
                    }}
                  >
                    Add
                  </Button>
                </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.root}>

          {paramList.map((param, index) => {
            const fieldId = `dataKey${index + 1}`;

            return (
              <Grid item xs={12} style={{
                backgroundColor: keyFocus === index ? "#dedede" : "inherit"
              }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        name={fieldId}
                        label="Paramater Name"
                        value={param.name}
                        variant="outlined"
                        onFocus={evt => {
                          setKeyFocus(index);
                        }}
                        onChange={(evt) =>{
                          renameParam(index, evt.target.value);
                          setKeyFocus(index);
                        }}
                        onBlur={(evt) => {
                          renameParam(index, evt.target.value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        name={`dataValue${index}`}
                        label="Paramater Value"
                        value={param.value || ""}
                        variant="outlined"
                        onFocus={evt => {
                          setKeyFocus(index);
                        }}
                        onChange={(evt) =>{
                          changeValue(index, evt.target.value, true);
                        }}
                        onBlur={(evt) => {
                          changeValue(index, evt.target.value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} alignItems="center" className={classes.buttonGrid}>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => {
                          deleteParam(index);
                        }}
                      >
                        <DeleteForeverIcon />
                      </Button>
                    </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>

        <Modal />
      </Grid>
  );
}

export const KeyValueEditor = forwardRef(keyValueEditor);
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import LaunchIcon from "@material-ui/icons/Launch";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GET_CONTRACT_RESPONSE_PARSING } from "../../common/models/contractResponseParsing";
import { GET_CONTRACT_RESPONSE_TOKEN } from "../../common/models/contractResponseToken";
import { GET_RESPONSE_DISPOSITION } from "../../common/models/responseDisposition";
import { GetContractResponseParsing } from "../../common/models/types/GetContractResponseParsing";
import { GetContractResponseToken } from "../../common/models/types/GetContractResponseToken";
import { GetResponseDisposition } from "../../common/models/types/GetResponseDisposition";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { queryLoad, StatusIcon, useModal } from "../../components";
import { booleanRadioFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { RootState } from "../../state";
import { updateContractDetailForms } from "../../state/contractSectionReducer";
import {
  dockForm,
  FormStateProps,
  openForm,
  resetForm,
} from "../../state/formReducer";
import HistoryModal from "../history/HistoryModal";
import { ContractResponseParsingModal } from "./contractResponseParsingModal";
import { ContractResponseTokenModal } from "./contractResponseTokenModal";

interface ContractResponseProps {
  contractId: String;
  contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps;
}

//Update props types once view requirements are finalized
const ContractResponse = ({
  contractId,
  dispatch,
  contractForms,
}: ContractResponseProps) => {
  const [
    getContractResponseParsing,
    {
      data: responseParsingData,
      loading: responseParsingLoading,
      error: responseParsingError,
      refetch: responseParsingRefetch,
    },
  ] = useLazyQuery<GetContractResponseParsing>(GET_CONTRACT_RESPONSE_PARSING, {
    fetchPolicy: "cache-and-network",
  });
  const [
    getContractResponseToken,
    {
      data: responseTokenData,
      loading: responseTokenLoading,
      error: responseTokenError,
      refetch: responseTokenRefetch,
    },
  ] = useLazyQuery<GetContractResponseToken>(GET_CONTRACT_RESPONSE_TOKEN, {
    fetchPolicy: "cache-and-network",
  });
  const [
    getResponseDisposition,
    {
      data: responseDispositionData,
      error: responseDispositionError,
      loading: responseDispositionLoading,
    },
  ] = useLazyQuery<GetResponseDisposition>(GET_RESPONSE_DISPOSITION);

  const LDP_CONTRACT_RESPONSE_MAPPING_TABLE_ID = `contract-response-mapping-${contractId}`;
  const LDP_CONTRACT_RESPONSE_TOKENS_TABLE_ID = `contract-response-tokens-${contractId}`;

  const [responseMapping, setResponseMapping] = useState<any>();

  useEffect(() => {
    getContractResponseParsing({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, []);

  useEffect(() => {
    if (responseParsingData || responseParsingError)
      getContractResponseToken({
        variables: { where: `ContractId = ${contractId}` },
      });
  }, [responseParsingData, responseParsingError]);

  useEffect(() => {
    if (responseTokenData || responseTokenError) getResponseDisposition();
  }, [responseTokenData, responseTokenError]);

  useEffect(() => {
    //generate responseMapping data
    if (responseParsingData && responseDispositionData) {
      setResponseMapping(
        responseParsingData.LDPConfigQueryGroup?.ContractResponseParsing?.map(
          (responseParsing) => {
            return {
              ...responseParsing,
              ResponseDisposition:
                responseDispositionData.LDPConfigQueryGroup?.ResponseDisposition?.find(
                  (responseDisposition) => {
                    return (
                      responseDisposition?.ResponseDispositionId ===
                      responseParsing?.ResponseDispositionId
                    );
                  }
                )?.ResponseDispositionName +
                " - " +
                responseParsing?.ResponseDispositionId,
            };
          }
        )
      );
    }
  }, [responseDispositionData, responseParsingData]);

  const { Modal, closeModal, openModal } = useModal();

  const ResponseParsingColumnsMUI = [
    {
      name: "DataEditCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  openModal({
                    title: `Edit Response Mapping`,
                    icon: <EditIcon />,
                    iconColor: "orange",
                    content: (
                      <ContractResponseParsingModal
                        action={"edit"}
                        contractId={contractId}
                        data={mapRowDataToColumns(
                          tableMeta.rowData,
                          ResponseParsingColumnsMUI
                        )}
                        close={closeModal}
                        refetch={responseParsingRefetch}
                      />
                    ),
                  });
                }}
              >
                <LaunchIcon />
              </IconButton>
            </>
          );
        },
      },
    },

    {
      name: "DataHistoryCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="textPrimary">History</Typography>
                  </React.Fragment>
                }
              >
                <IconButton
                  onClick={() => {
                    const HISTORY_TAB_ID = `contract-${contractId}-response-mapping-history-${tableMeta.rowData[3]}`;
                    const formProps = {
                      formId: HISTORY_TAB_ID,
                      formTitle: `Response Mapping ${tableMeta.rowData[3]} History`,
                      formIcon: <HistoryIcon />,
                      formComponent: (
                        <HistoryModal
                          ConfigName="ContractResponseParsing"
                          ConfigId={tableMeta.rowData[3]}
                          TabContainerId={HISTORY_TAB_ID}
                        />
                      ),
                      formData: {},
                      formProps: {
                        closeTab: () =>
                          dispatch(resetForm({ formId: HISTORY_TAB_ID })),
                      },
                    };

                    dispatch(
                      updateContractDetailForms({
                        contractId: `contract-${contractId}`,
                        formIds: [HISTORY_TAB_ID],
                      })
                    );

                    dispatch(
                      contractForms.formContainers[HISTORY_TAB_ID] &&
                        contractForms.formContainers[HISTORY_TAB_ID]
                          .isLastOpenedDocked
                        ? dockForm(formProps)
                        : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },

    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "ContractResponseParsingId",
      label: "Contract Response Parsing Id",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "ResponseValue",
      label: "Response",
      options: {
        filter: false,
      },
    },
    {
      name: "ResponseDisposition",
      label: "Disposition",
      options: {
        filter: false,
      },
    },
    {
      name: "OrderOfOperation",
      label: "Order",
      options: {
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "Active",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: [],
      },
    },
    {
      name: "IsPing",
      label: "Ping",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsPing: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "IsWordBoundaryMatch",
      label: "Boundary Match",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },

    {
      name: "ResponseValueIsCaseSensitive",
      label: "Case Sensitive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },

    {
      name: "ResponseDispositionId",
      label: "ResponseDispositionId",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {},
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
  ];

  const mapRowDataToColumns = (rowData: any, columns: any) => {
    if (rowData) {
      let newJson: any = {};
      columns.map((column: any, key: any) => {
        newJson[column.name] = rowData[key];
      });
      return newJson;
    } else return null;
  };

  const ResponseTokenColumnsMUI = [
    {
      name: "DataEditCol",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <IconButton
              onClick={() => {
                openModal({
                  title: `Edit Token`,
                  icon: <EditIcon />,
                  iconColor: "orange",
                  content: (
                    <ContractResponseTokenModal
                      action={"edit"}
                      contractId={contractId}
                      data={mapRowDataToColumns(
                        tableMeta.rowData,
                        ResponseTokenColumnsMUI
                      )}
                      close={closeModal}
                      refetch={responseTokenRefetch}
                    />
                  ),
                });
              }}
            >
              <LaunchIcon />
            </IconButton>
          );
        },
      },
    },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "ContractResponseTokenId",
      label: "ContractResponseTokenId",
      options: {
        filter: false,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        ...booleanRadioFilter("Is Active"),
        filterList: [],
      },
    },
    {
      name: "IsHtml",
      label: "IsHtml",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsHtml: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "IsPing",
      label: "IsPing",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No"),
        },
        customFilterListOptions: {
          render: (v: any) => `IsPing: ${v ? "Yes" : "No"}`,
        },
      },
    },
    {
      name: "TokenName",
      label: "TokenName",
      options: {
        filter: false,
      },
    },
    {
      name: "TokenPath",
      label: "TokenPath",
      options: {
        filter: false,
      },
    },
    {
      name: "TokenPathIsCaseSensitive",
      label: "TokenPathIsCaseSensitive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: false,
      },
    },
    {
      name: "TokenType",
      label: "TokenType",
      options: {},
    },
    {
      name: "UserId",
      label: "UserId",
      options: {},
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
          return value ? dateToPreferredTimezone(value, "yyyy-MM-dd") : "";
        },
        filter: false,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: "OrderOfOperation",
      direction: "asc",
    },
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: `Add New Response Mapping`,
                    icon: <AddCircle />,
                    iconColor: "orange",
                    content: (
                      <ContractResponseParsingModal
                        action={"create"}
                        data={null}
                        contractId={contractId}
                        close={closeModal}
                        refetch={responseParsingRefetch}
                      />
                    ),
                  });
                }}
              >
                Create New Mapping
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!responseParsingLoading], [responseParsingError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_CONTRACT_RESPONSE_MAPPING_TABLE_ID}
            restoreFilters={true}
            title={"Response Mapping"}
            data={responseMapping}
            columns={ResponseParsingColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  openModal({
                    title: `Add New Token`,
                    icon: <AddCircle />,
                    iconColor: "orange",
                    content: (
                      <ContractResponseTokenModal
                        action={"create"}
                        data={null}
                        contractId={contractId}
                        close={closeModal}
                        refetch={responseTokenRefetch}
                      />
                    ),
                  });
                }}
              >
                Create New Token
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!responseTokenLoading], [responseTokenError]) || (
          <LDPUIDataTable
            ldpTableId={LDP_CONTRACT_RESPONSE_TOKENS_TABLE_ID}
            restoreFilters={true}
            title={"Tokens"}
            data={responseTokenData?.LDPConfigQueryGroup?.ContractResponseToken}
            columns={ResponseTokenColumnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
    </Grid>
  );
};

export default connect(
  (state: RootState) => ({
    contractRequestBodyRecordSaved:
      state.contractRequestSection.contractRequestBodyRecordSaved,
    contractForms: state.formsSection,
  }),
  null
)(ContractResponse);

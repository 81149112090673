import React, { useEffect, useState } from "react";
import {
  Button, createStyles, Divider, Grid, InputAdornment, makeStyles, Paper, TextField, Theme, Tooltip, Typography, FormControl, InputLabel, List, ListItem, ListItemText, Card, CardContent, ListItemAvatar, Badge
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import SaveIcon from "@material-ui/icons/Save";

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { cloneObject } from "../../common/utils/objectTools";

interface ContractParsingFormModalProps {
  data: string | null;
  action: string;
  onSave: Function;
  close: Function;
}

interface FlaggedZip {
  [group: string]: string[];
}

const INVALID_LENGTH = 'Invalid Length';
const INVALID_FORMAT = 'Invalid Format';
const EXISTING_ZIP = 'Already Exists';

const EMPTY_FLAGGED_LIST: FlaggedZip = {
  [INVALID_FORMAT]: [],
  [INVALID_LENGTH]: [],
  [EXISTING_ZIP]: [],
};

/* const formError = {
  zipCode: {
    required: {
      value: true,
      message: "Zip Code value is required.",
    },
  },
}; */

const pad = (num: string, size: number) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export const ContractZipCodeFilterModal = ({
  action,
  data,
  onSave,
  close
}: ContractParsingFormModalProps) => {
  const { register, handleSubmit, errors } = useForm<
    {
      action: string;
      zipCode: string;
    }
  >();

  const classes = useStyles();

  const drop = React.useRef<HTMLElement>(null);

  const pageTitle =
    action == "create" ? "Add Zip Code" : "Edit Zip Code";

  const [zipCodeList, setZipCodeList] = useState<string[]>([]);
  const [flaggedZipCodeList, setFlaggedZipCodeList] = useState<FlaggedZip>(cloneObject(EMPTY_FLAGGED_LIST));
  const [zipCodes, setZipCodes] = useState<string>('');
  const [dragging, setDragging] = useState(false);

  const onSubmit = (form: any) => {
    onSave(action, zipCodes, data);
  };

  const processFile = (file: File) => {
    const fr = new FileReader();

    fr.onload = () => {
      const zipList: string[] = [];
      const flagged: FlaggedZip = cloneObject(EMPTY_FLAGGED_LIST);

      let content = fr.result;

      if (content) {
        var text = '';

        if (typeof content !== 'string') {
          text = String(content).toString().trim();
        } else {
          text = content.trim();
        }

        const lines = text.replace("\r", "\n").split("\n");

        for (const line of lines) {
          const l = line.trim();

          if (l !== '') {
            const zips = l.replace(/,/g, "|").split("|").map(z => z.trim());

            for (const zip of zips) {
              const z = zip.trim();

              if (z !== '' && z.match(/^\d+$/)) {
                if (z.length <= 5) {
                  zipList.push(pad(z, 5));
                } else {
                  if (!flagged[INVALID_LENGTH].find((fl) => fl === z)) flagged[INVALID_LENGTH].push(z);
                }
              } else {
                if (!flagged[INVALID_FORMAT].find((fl) => fl === z)) flagged[INVALID_FORMAT].push(z);
              }
            }
          }
        }

        setZipCodeList((prevValue) => {
          const cleanDeduped: string[] = [...prevValue.filter((z) => !!z)];

          for (const dataZip of zipList) {
            if (!cleanDeduped.includes(dataZip)) {
              cleanDeduped.push(dataZip);
            } else {
              if (!flagged[EXISTING_ZIP].find((fl) => fl === dataZip)) flagged[EXISTING_ZIP].push(dataZip);
            }
          }

          setFlaggedZipCodeList(flagged);

          return cleanDeduped;
        });
      }
    }

    fr.readAsText(file);
  }

  const processSelectedFiles = (files: FileList) => {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const csvFile = files.item(i);

        if (csvFile && csvFile.name.toLowerCase().endsWith('.csv') && ["text/csv", "application/vnd.ms-excel"].includes(`${csvFile?.type}`)) {
          processFile(csvFile);
        }
      }
    }
  }

  const onDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
  }

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    if (e.dataTransfer) {
      const { files } = e.dataTransfer;
      processSelectedFiles(files);
    }

  }

  const onDragEnd = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);
  }

  useEffect(() => {
    setZipCodeList(() => {
      const cleanDeduped: string[] = [];
      const dataZips = (data || '').split(",").map(s => s.trim());

      for (const dataZip of dataZips) {
        if (!cleanDeduped.includes(dataZip)) {
          cleanDeduped.push(dataZip);
        }
      }

      return cleanDeduped;
    });
  }, [data]);

  useEffect(() => {
    setZipCodes(zipCodeList.join(", "));
  }, [zipCodeList]);

  useEffect(() => {
    if (drop && drop.current) {
      drop?.current?.addEventListener('dragleave', onDragEnd);
      drop?.current?.addEventListener('dragover', onDragOver);
      drop?.current?.addEventListener('drop', onDrop);

      return () => {
        drop?.current?.removeEventListener('dragleave', onDragEnd);
        drop?.current?.removeEventListener('dragover', onDragOver);
        drop?.current?.removeEventListener('drop', onDrop);
      };
    }
  }, [drop]);

  const [copyButtonTxt, setCopyButtonTxt] = useState<string>("Copy Zip Codes");
  useEffect(() => {
    if (copyButtonTxt === 'Copied!') {
      setTimeout(() => {
        setCopyButtonTxt("Copy Zip Codes");
      }, 5000);
    }
  }, [copyButtonTxt]);

  useEffect(() => {
    setFlaggedZipCodeList(cloneObject(EMPTY_FLAGGED_LIST));
  }, []);

  return (
    <Paper ref={drop} className={classes.contrainer}>
      {dragging ? (
        <Grid className={classes.mainGrid} container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12}>
            <Typography variant="h2" component="h3" align="center">
              Drop Files Here
              </Typography>
          </Grid>
        </Grid>
      ) : (
          <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <Grid className={classes.mainGrid} container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={12}>
                <TextField
                  multiline
                  rowsMax={10}
                  inputRef={register(/* formError.zipCode */)}
                  error={errors.zipCode && true}
                  helperText={
                    errors.zipCode && errors.zipCode?.message
                  }
                  name="zipCode"
                  label="Zip Code (s)"
                  variant="outlined"
                  value={zipCodes}
                  onChange={(e) => {
                    const re = /^[0-9\s\|,]+$/;

                    // if value is not blank, then test the regex
                    let inputValue = e.target.value.trim();
                    if (inputValue === '' || re.test(inputValue)) {
                      setZipCodes(inputValue);
                    }
                  }}
                  InputProps={{
                    startAdornment:
                      <InputAdornment position="start">
                        <Tooltip title={"Zip Code or Zip Codes (comma or pipe delimited)"}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  Upload CSV
                <input
                    type="file"
                    onChange={(evt) => (evt.target.files && processSelectedFiles(evt.target.files))}
                    hidden
                  />
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  size="large"
                  fullWidth
                  onClick={() => {
                    if ('clipboard' in navigator) {
                      navigator.clipboard.writeText(zipCodes);
                    } else {
                      document.execCommand('copy', true, zipCodes);
                    }

                    setCopyButtonTxt('Copied!');
                  }}
                >
                  {copyButtonTxt}
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  type="button"
                  size="large"
                  fullWidth
                  onClick={() => close()}
                >
                  Cancel
              </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                  disabled={zipCodes === data}
                  fullWidth
                  startIcon={<SaveIcon />}
                >
                  Save
              </Button>
              </Grid>

              <Grid item xs={12} >
                <List>
                  {Object.keys(flaggedZipCodeList).map((reason) => {
                    if (flaggedZipCodeList[reason].length === 0) {
                      return null;
                    }

                    return (
                      <ListItem>
                        <ListItemText primary={reason} secondary={flaggedZipCodeList[reason].join(", ")} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>

          </form>
        )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dropZone: {
      margin: "10px",
    },
    errorList: {
      maxHeight: "300px",
      overflowY: "auto"
    }
  })
);

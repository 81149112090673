import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Collapse,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from "@material-ui/icons/Launch";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import SyncIcon from "@material-ui/icons/Sync";
import TabIcon from "@material-ui/icons/Tab";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GET_CONTRACT_REQUEST_SCHEMA } from "../../common/models/contractRequestSchema";
import { GET_LEAD_DETAIL } from "../../common/models/leadDataLookup";
import { RUN_LIVE_REPORTS } from "../../common/models/liveReport";
import { PING_TEST_CONTRACT } from "../../common/models/ping";
import { GetContractRequestSchema } from "../../common/models/types/GetContractRequestSchema";
import { GetLeadDetail } from "../../common/models/types/GetLeadDetail";
import {
  PingTest,
  PingTestVariables,
} from "../../common/models/types/PingTest";
import { RunLiveReport } from "../../common/models/types/RunLiveReport";
import TestPingResult from "../../components/testPingResult";
import { RootState } from "../../state";
import {
  FormStateProps,
  toggleForm,
  updateForm,
} from "../../state/formReducer";
import { deleteTabContainers } from "../../state/tabsReducer";
import { TestPingInputType } from "../../types/graphql-global-types";

declare const window: any;

interface ContractPingTestFormModalProps {
  data: {
    ContractId: number;
    VerticalName: string;
    SubVerticalName: string;
    VerticalId: any;
    SubVerticalId: any;
    Route: string;
    ContractResponseTokens: string;
    TestLeadId: string;
    lastTestResult: any;
  };
  isDocked: boolean;
  formId: string;
  close: Function;
  contractForm: FormStateProps;
  dispatch: Function;
}

interface ActiveParsedTokensProps {
  TestLeadId: string | undefined;
  ParsedTokens: string | null;
}

interface TestResultProps {
  TestLeadId: string | undefined;
  TestType: string | null;
  TestResponse: PingTest;
}

interface TestSummaryProps {
  responseObject: string | null;
  errorMessage: string | null;
  isComplete: boolean;
  isSuccess: boolean;
}

const numericPattern = /^[0-9]+(\.[0-9]+)?$/s;

const formError = {
  Route: {
    required: {
      value: true,
      message: "Test Type is required.",
    },
  },
  TestLeadId: {
    required: {
      value: true,
      message: "Test Lead Id is required.",
    },
    pattern: {
      value: numericPattern,
      message: "Test Lead Id must be numeric.",
    },
  },
};

interface TestResultState {
  responseType: string;
  errorMessage: String;
  isComplete: Boolean;
  isSuccess: Boolean;
  payload: string | null;
  responsePayload: string | null;
}

interface SchemaNameType {
  ACTIVE?: string;
  INACTIVE?: string;
}

const getLocalStorageWithExpiry = (key: any) => {
  const itemStr = localStorage.getItem(key);

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const ContractPingTestFormModal = ({
  data,
  close,
  isDocked,
  formId,
  contractForm,
  dispatch,
}: ContractPingTestFormModalProps) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    getValues,
    setValue,
  } = useForm<TestPingInputType>();

  const TestTypes = [
    "BuildPingRequest",
    "TestPing",
    "BuildPostRequest",
    "TestPost",
  ];

  const [activeTestType, setActiveTestType] = useState<string | null>(null);
  const [activeTestLeadId, setActiveTestLeadId] = useState<
    string | null | undefined
  >("");
  const [activeParsedTokens, setActiveParsedTokens] = useState<
    ActiveParsedTokensProps | undefined
  >();
  const [pingSchemaName, setPingSchemaName] = useState<SchemaNameType>();
  const [postSchemaName, setPostSchemaName] = useState<SchemaNameType>();

  const submittedPayload = JSON.parse(
    localStorage.getItem(`${formId}-submitted`) ?? "{}"
  );
  const [customPayload, setCustomPayload] = useState(
    Object.keys(submittedPayload).length > 0
      ? submittedPayload
      : getLocalStorageWithExpiry(`contract-${data.ContractId}`)
  );

  const [
    getLeadDetails,
    { data: LeadData, called, error, loading },
  ] = useLazyQuery<GetLeadDetail>(GET_LEAD_DETAIL, {
    fetchPolicy: "network-only",
  });

  const {
    data: requestSchemaData,
    loading: requestSchemaDataLoading,
    error: requestSchemaError,
    refetch: requestSchemaRefetch,
  } = useQuery<GetContractRequestSchema>(GET_CONTRACT_REQUEST_SCHEMA, {
    variables: { where: `ContractId = ${data.ContractId}` },
    fetchPolicy: "network-only",
  });

  const [
    doPingTest,
    {
      data: pingTestData,
      loading: pingTestLoading,
      called: pingTestCalled,
      refetch: pingTestRefetch,
    },
  ] = useLazyQuery<PingTest, PingTestVariables>(PING_TEST_CONTRACT, {
    fetchPolicy: "no-cache",
  });

  const [getLiveReport, { data: reportData }] = useLazyQuery<RunLiveReport>(
    RUN_LIVE_REPORTS,
    {
      fetchPolicy: "network-only",
      variables: {
        StoredProcName: `[LDP.Ingest].[lead].[ProcGet${data?.VerticalName}${data?.SubVerticalName}TestLeads]`,
        DatabaseName: "ldp",
        FieldNames: [],
        FieldValues: [],
        FetchEDW: false, //default to false since this StoredProcName is not yet EDW supported
      },
    }
  );

  const [isCheckedCustomPayload, setIsCheckedCustomPayload] = useState<boolean>(
    getLocalStorageWithExpiry(`contract-${data.ContractId}`) ? true : false
  );

  const watchTestLeadId = watch("TestLeadId");

  useEffect(() => {
    if (activeTestLeadId) {
      getLeadDetails({
        variables: {
          LeadDataId: activeTestLeadId || null,
          VerticalId: data?.VerticalId || null,
          SubVerticalId: data?.SubVerticalId || null,
          FetchEDW: false, // default to false since there is not reference to startdate
        },
      });
    }
  }, [activeTestLeadId, isCheckedCustomPayload]);

  useEffect(() => {
    if (LeadData && LeadData?.LDPIngestQueryGroup?.GetLeadDetail) {
      let leadDetails =
        JSON.parse(LeadData?.LDPIngestQueryGroup?.GetLeadDetail ?? "")?.pop() ??
        {};
      let leadDetailsPayload = {};

      if (leadDetails.Payload) {
        try {
          leadDetailsPayload = JSON.parse(leadDetails.Payload);
        } catch {
          leadDetailsPayload = { Payload: leadDetails.Payload };
        }
        delete leadDetails.Payload;
      }

      const mappedLeadDetails = { ...leadDetails, ...leadDetailsPayload };

      if (leadDetails && mappedLeadDetails) {
        if (mappedLeadDetails?.LeadDataId != customPayload?.LeadDataId) {
          setCustomPayload(mappedLeadDetails);
        } else {
          setCustomPayload({ ...mappedLeadDetails, ...customPayload });
        }
      }
    }
  }, [LeadData]);

  const [formData, setFormData] = useState<any>(
    contractForm.formContainers[formId].formData || data
  );

  useEffect(() => {
    if (!requestSchemaDataLoading && requestSchemaData) {
      const pingSchemaNames: SchemaNameType = {};
      const postSchemaNames: SchemaNameType = {};

      requestSchemaData.LDPConfigQueryGroup?.ContractRequestSchema?.forEach(
        (schema) => {
          if (schema?.IsPing === true && schema?.IsActive)
            pingSchemaNames.ACTIVE =
              schema.ContractRequestSchemaName || undefined;
          if (schema?.IsPing === true && !schema?.IsActive)
            pingSchemaNames.INACTIVE =
              schema.ContractRequestSchemaName || undefined;
          if (schema?.IsPing === false && schema?.IsActive)
            postSchemaNames.ACTIVE =
              schema.ContractRequestSchemaName || undefined;
          if (schema?.IsPing === false && !schema?.IsActive)
            postSchemaNames.INACTIVE =
              schema.ContractRequestSchemaName || undefined;
        }
      );
      if (Object.keys(pingSchemaNames).length > 0)
        setPingSchemaName(pingSchemaNames);
      if (Object.keys(postSchemaNames).length > 0)
        setPostSchemaName(postSchemaNames);
    }
  }, [requestSchemaDataLoading, requestSchemaData]);

  const onSubmit = (pingInput: TestPingInputType) => {
    if (Object.keys(IsActiveRequestSchema).length > 0) {
      pingInput = {
        ...pingInput,
        IsActiveRequestSchema:
          IsActiveRequestSchema.key === "ACTIVE" ? true : false,
      };
    }

    const pingTestModalScrollable = document.querySelector(
      "#ModalScrollController"
    );
    localStorage.setItem(
      "PingTestModalScrollPosition",
      `${(pingTestModalScrollable?.scrollTop ?? 0) + 300}`
    );
    if (pingInput?.ContractResponseTokens) {
      pingInput = {
        ...pingInput,
        ContractResponseTokens: JSON.parse(
          pingInput?.ContractResponseTokens ?? ""
        ),
      };
    }

    dispatch(deleteTabContainers(formId));

    doPingTest({
      variables: {
        pingInput,
      },
    });

    localStorage.setItem(`${formId}-submitted`, JSON.stringify(customPayload));
  };

  useEffect(() => {
    if (reportData) {
      const remoteLeads = JSON.parse(
        reportData?.LDPConfigQueryGroup?.RunLiveReport || "[]"
      );
      const randpos = Math.floor(Math.random() * remoteLeads.length);
      const lead = remoteLeads[randpos] ?? null;
      if (lead) {
        const LeadId = lead[`${data?.VerticalName}${data?.SubVerticalName}Id`];
        setValue("TestLeadId", LeadId, { shouldValidate: true });
        setActiveTestLeadId(LeadId);
      }
    }
  }, [reportData]);

  useEffect(() => {
    setActiveTestLeadId(watchTestLeadId);
  }, [watchTestLeadId]);

  useEffect(() => {
    if (pingTestData) {
      const testPing = pingTestData?.LDPConfigQueryGroup?.PingTest;
      const postedTestPingParams = getValues();

      dispatch(
        updateForm({
          formId: formId,
          formData: {
            ...formData,
            ...control.getValues(),
            isCheckedCustomPayload: isCheckedCustomPayload,
            ContractResponseTokens: activeParsedTokens?.ParsedTokens || null,
            IsActiveRequestSchema: IsActiveRequestSchema,
            postedTestPing: {
              params: postedTestPingParams,
              testPing,
            },
          },
        })
      );
    }

    bottomRef.current?.focus();
  }, [pingTestData]);

  useEffect(() => {
    const _formData = contractForm.formContainers[formId].formData;
    if (_formData) {
      if (_formData?.Route) {
        setActiveTestType(_formData?.Route);
        setIsActiveRequestSchema(_formData?.IsActiveRequestSchema);
      }

      const pingResponse = _formData?.postedTestPing?.testPing?.pingResponse;
      if (pingResponse && pingResponse?.parsedTokens) {
        setActiveParsedTokens({
          TestLeadId: _formData?.postedTestPing?.params?.TestLeadId,
          ParsedTokens: pingResponse?.parsedTokens,
        });
      }

      const postResponse = _formData?.postedTestPing?.testPing?.postResponse;
      if (postResponse && postResponse?.parsedTokens) {
        setActiveParsedTokens({
          TestLeadId: _formData?.postedTestPing?.params?.TestLeadId,
          ParsedTokens: postResponse?.parsedTokens,
        });
      }

      if (_formData?.isCheckedCustomPayload)
        setIsCheckedCustomPayload(_formData?.isCheckedCustomPayload);
      setFormData(_formData);
    }
  }, [contractForm, pingSchemaName, postSchemaName]);

  const getTestLead = () => {
    getLiveReport();
  };

  const setLocalStorageWithExpiry = (key: any, value: any, ttl: any) => {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const [payloadFocus, setPayloadFocus] = useState<any>(null);
  const [payloadCursor, setPayloadCursor] = useState([0, 0]);
  const [generatingLeadToken, setGeneratingLeadToken] = useState(false);
  /* const [generatingTrustedForm, setGeneratingTrustedForm] = useState(false);
  const [trustedFormGenerated, setTrustedFormGenerated] = useState(false); */
  const [IsActiveRequestSchema, setIsActiveRequestSchema] = useState<
    Record<string, string>
  >({});

  const generateNewJornayaToken = () => {
    var s = document.createElement("script");
    s.id = "LeadiDscript_campaign";
    s.type = "text/javascript";
    s.async = true;
    s.src =
      "//create.lidstatic.com/campaign/f21bcfe7-421d-0b59-65fe-3d36e8d9c8d6.js?snippet_version=2&f=reset&callback=jornayaLeadId";
    var LeadiDscript = document.getElementsByTagName("head")[0];
    LeadiDscript?.appendChild(s);
  };
  /* TRUSTED FORM CODE
  const generateNewTrustedForm = () => {
    const field = "xxTrustedFormCertUrl";
    const provideReferrer = false;
    const invertFieldSensitivity = false;
    const tf = document.createElement("script");
    tf.id = "TrustedForm_campaign";
    tf.type = "text/javascript";
    tf.crossorigin = "use-credentials";
    tf.async = true;
    tf.src = `http${
      document.location.protocol === "https:" ? "s" : ""
    }://cdn.trustedform.com/bootstrap.js?provide_referrer=${escape(
      provideReferrer
    )}&field=${escape(
      field
    )}&l=${new Date().getTime()}${Math.random()}&invert_field_sensitivity=${invertFieldSensitivity}`;

    const s = document.getElementsByTagName("script")[0];
    if (s) {
      // s.parentNode.insertBefore(tf, s);
      document.body.appendChild(tf);
    }
  };
  */
  // // -->
  // function jornayaLeadId(token) {
  //   console.log(
  //     "%c LeadiD.token = " + LeadiD.token,
  //     "background: #FD5B78; color: #FFFFFF"
  //   );
  // }

  const checkWindowVariable = () => {
    setGeneratingLeadToken(true);
    try {
      if (typeof window.LeadiD !== "undefined") {
        window.LeadiD.reInit();
      } else {
        generateNewJornayaToken();
      }
    } catch (error) {
      console.log("jornaya error", error);
    }
    const intervalId = setInterval(() => {
      // Check the window variable here
      if (window?.LeadiD?.token !== undefined) {
        // Perform desired actions
        setPayloadFocus("UniversalLeadId");
        updateCustomPayload("UniversalLeadId", window?.LeadiD?.token);
        setGeneratingLeadToken(false);

        // Clear the interval if the condition is met
        clearInterval(intervalId);
      }
    }, 1000); // 1000 milliseconds = 1 second
  };

  const addCustomPayload = (key: string, value: any) => {
    const re = new RegExp("^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$");

    if (!re.test(key))
      return toast.error("Invalid parameter name. Must be start with letter!");

    setCustomPayload({ ...customPayload, [key]: value });
    setLocalStorageWithExpiry(
      `contract-${data.ContractId}`,
      { ...customPayload, [key]: value },
      24 * 3600000
    ); //24 hours
  };

  const updateCustomPayload = (key: string, value: any) => {
    setCustomPayload({ ...customPayload, [key]: value });
  };

  const removeCustomPayload = (key: string) => {
    let payloads = {};
    for (let name in customPayload) {
      if (name === key) continue;

      payloads = { ...payloads, [name]: customPayload[name] };
    }

    setCustomPayload(payloads);
    setLocalStorageWithExpiry(
      `contract-${data.ContractId}`,
      payloads,
      24 * 3600000
    ); //24 hours
  };

  const [isExpand, setIsExpand] = useState(false);
  const CustomPayloadField = ({
    customPayload,
    addCustomPayload,
    updateCustomPayload,
    removeCustomPayload,
    payloadFocus,
    setPayloadFocus,
    payloadCursor,
    setPayloadCursor,
    isExpand,
    setIsExpand,
  }: any) => {
    const [paramKey, setParamKey] = useState("");
    const [paramVal, setParamVal] = useState("");

    let fields = [];
    if (customPayload) {
      for (let key in customPayload) {
        fields.push({
          name: key,
          value: customPayload[key],
        });
      }
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <TextField
              id="custom-payload-name"
              value={paramKey}
              variant="outlined"
              label="Parameter Name"
              onChange={(e: BaseSyntheticEvent) => setParamKey(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <TextField
              id="custom-payload-content"
              variant="outlined"
              value={paramVal}
              label="Parameter Value"
              onChange={(e: BaseSyntheticEvent) => setParamVal(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <IconButton
              edge="end"
              onClick={() => {
                addCustomPayload(paramKey, paramVal);
                setParamKey("");
                setParamVal("");
              }}
            >
              <NoteAddIcon />
            </IconButton>

            {fields?.length > 0 ? (
              <IconButton
                edge="end"
                onClick={() => {
                  setIsExpand(!isExpand);
                }}
              >
                <Badge badgeContent={fields.length} color="primary">
                  {!isExpand ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Badge>
              </IconButton>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Collapse in={isExpand}>
          {customPayload &&
            fields.map((item, index) => (
              <Box my={2} key={index}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    lg={item.name !== "UniversalLeadId" ? 12 : 9}
                  >
                    <TextField
                      autoFocus={item.name === payloadFocus}
                      key={`custom-payload-${item.name}`}
                      id={`custom-payload-${item.name}`}
                      defaultValue={item.value}
                      variant="outlined"
                      name={item.name}
                      label={item.name}
                      onFocus={(e: BaseSyntheticEvent) => {
                        setPayloadFocus(item.name);
                        e.target.selectionStart = payloadCursor[0];
                        e.target.selectionEnd = payloadCursor[1];
                      }}
                      onChange={(e: BaseSyntheticEvent) => {
                        setPayloadCursor([
                          e.target.selectionStart,
                          e.target.selectionEnd,
                        ]);
                        updateCustomPayload(e.target.name, e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => {
                                removeCustomPayload(item.name);
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    hidden={
                      item.name !== "UniversalLeadId"
                      // && item.name !== "TrustedFormCertUrl"
                    }
                  >
                    {item.name === "UniversalLeadId" && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        startIcon={<SyncIcon />}
                        disabled={generatingLeadToken}
                        onClick={() => {
                          checkWindowVariable();
                        }}
                      >
                        {generatingLeadToken
                          ? "Generating..."
                          : "Generate New Token"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ))}
        </Collapse>
      </>
    );
  };

  const handleToggleFormDocking = () => {
    dispatch(
      toggleForm({
        formId: formId,
        formData: {
          ...formData,
          ...getValues(),
        },
      })
    );
  };

  return (
    <Paper className={classes.container}>
      {!isDocked && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "15px",
            marginTop: "-86px",
          }}
        >
          <Tooltip title="Dock to tab">
            <IconButton aria-label="settings" onClick={handleToggleFormDocking}>
              <TabIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton
              aria-label="settings"
              onClick={() => {
                localStorage.removeItem(`${formId}-submitted`);
                close();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {isDocked && (
        <div style={{ textAlign: "right", marginRight: "10px" }}>
          <Tooltip title="Popup">
            <IconButton aria-label="settings" onClick={handleToggleFormDocking}>
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={formData?.ContractId}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="VerticalName"
              defaultValue={formData?.VerticalName}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="SubVerticalName"
              defaultValue={formData?.SubVerticalName}
            />
            <Autocomplete
              id="test-type-input"
              options={TestTypes}
              value={activeTestType}
              onChange={(event: any, newValue: string | null) => {
                // setIsActiveRequestSchema('');
                setActiveTestType(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Test Type"
                  variant="outlined"
                  error={errors.Route ? true : false}
                  helperText={errors.Route && errors.Route?.message}
                  inputRef={register(formError.Route)}
                  name="Route"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(formError.TestLeadId)}
              error={errors.TestLeadId && true}
              helperText={errors.TestLeadId && errors.TestLeadId?.message}
              name="TestLeadId"
              label="Test Lead Id"
              defaultValue={formData?.TestLeadId}
              variant="outlined"
              InputLabelProps={{ shrink: watchTestLeadId ? true : false }}
            />
          </Grid>
          {activeTestType && (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  id="IsActiveSchema-label"
                  className={classes.formLabel}
                >
                  Active/Inactive Schema
                </InputLabel>
                <Select
                  value={JSON.stringify(IsActiveRequestSchema)}
                  error={errors.IsActiveRequestSchema && true}
                  onChange={(e) => {
                    setIsActiveRequestSchema(JSON.parse(e.target.value as string));
                  }}
                  variant="outlined"
                >
                  {Object.keys(IsActiveRequestSchema).length > 0 ? (
                    <MenuItem
                      key={`selected-schema`}
                      value={JSON.stringify(IsActiveRequestSchema)}
                    >
                      {IsActiveRequestSchema.key} - {IsActiveRequestSchema.value}
                    </MenuItem>
                  ) : null}

                  {activeTestType?.toLowerCase().includes("ping") ? (
                    pingSchemaName ? (
                      Object.entries(pingSchemaName)
                        .sort()
                        .map(([key, value]) => (
                          <MenuItem
                            key={`IsActiveRequestSchema-${key}`}
                            value={JSON.stringify({ key, value })}
                          >
                            {key} - {value}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem key="IsActiveRequestSchema-null" value="{}">
                        No available Request Schema
                      </MenuItem>
                    )
                  ) : postSchemaName ? (
                    Object.entries(postSchemaName)
                      .sort()
                      .map(([key, value]) => (
                        <MenuItem
                          key={`IsActiveRequestSchema-${key}`}
                          value={JSON.stringify({ key, value })}
                        >
                          {key} - {value}
                        </MenuItem>
                      ))
                  ) : (
                    <MenuItem key="IsActiveRequestSchema-null" value="{}">
                      No available Request Schema
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCheckedCustomPayload}
                  onChange={(event: BaseSyntheticEvent) =>
                    setIsCheckedCustomPayload(event?.target?.checked)
                  }
                />
              }
              label="Use Custom Payload"
            />

            <input
              type="hidden"
              name="xxTrustedFormCertUrl"
              id="xxTrustedFormCertUrl"
              value=""
            />
            <input
              type="hidden"
              name="xxTrustedFormPingUrl"
              id="xxTrustedFormPingUrl_0"
              value=""
            />

            {isCheckedCustomPayload && (
              <CustomPayloadField
                customPayload={customPayload}
                payloadFocus={payloadFocus}
                setPayloadFocus={setPayloadFocus}
                payloadCursor={payloadCursor}
                setPayloadCursor={setPayloadCursor}
                removeCustomPayload={removeCustomPayload}
                updateCustomPayload={updateCustomPayload}
                addCustomPayload={addCustomPayload}
                isExpand={isExpand}
                setIsExpand={setIsExpand}
              />
            )}

            {isCheckedCustomPayload && (
              <TextField
                hidden
                inputRef={register}
                name="TestLeadJsonString"
                value={JSON.stringify(customPayload)}
              />
            )}
          </Grid>
          {activeTestType == "TestPost" && (
            <Grid item xs={12}>
              {activeParsedTokens &&
              activeTestLeadId == activeParsedTokens?.TestLeadId ? (
                <TextField
                  id="contract-response-tokens-input"
                  value={activeParsedTokens?.ParsedTokens || ""}
                  variant="outlined"
                  name="ContractResponseTokens"
                  label="Parsed Tokens"
                  inputRef={register}
                  onChange={(event) => {
                    setActiveParsedTokens({
                      ...activeParsedTokens,
                      ParsedTokens: event.target.value,
                    });
                  }}
                />
              ) : (
                <Alert severity="warning">
                  No parsed tokens were found. Please run TestPing again
                </Alert>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {formData?.postedTestPing && (
              <div style={{ minHeight: "350px" }}>
                <TestPingResult formId={formId} />
              </div>
            )}
          </Grid>

          {pingTestLoading || requestSchemaDataLoading ? (
            <Grid item xs={4}>
              <LinearProgress />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Button
                disabled={pingTestLoading}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => {
                  setPayloadFocus(null);
                  getTestLead();
                }}
              >
                Get Test Lead
              </Button>
            </Grid>
          )}

          {pingTestLoading || requestSchemaDataLoading ? (
            <Grid item xs={4}>
              <LinearProgress />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Button
                disabled={pingTestLoading}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => {
                  localStorage.removeItem(`${formId}-submitted`);
                  close();
                }}
              >
                Close
              </Button>
            </Grid>
          )}

          {pingTestLoading || requestSchemaDataLoading ? (
            <Grid item xs={4}>
              <LinearProgress />
            </Grid>
          ) : (
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                onClick={() => setPayloadFocus(null)}
                startIcon={<SyncIcon />}
              >
                Test
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <div ref={bottomRef} />
    </Paper>
  );
};

export default connect(
  (state: RootState) => ({
    contractForm: state.formsSection,
  }),
  null
)(ContractPingTestFormModal);

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
      boxShadow: "none",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      width: "100%",
      marginBottom: "20px",
    },
    formLabel: {
      backgroundColor: "#FFFFFF",
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  })
);

import { IconButton, InputAdornment, Menu, MenuItem } from "@material-ui/core";
import React, { BaseSyntheticEvent, useState } from "react";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface MenuInputAdornmentProps {
    onSelect: Function;
    options: Array<String>;
}

export const MenuInputAdornment = ({onSelect, options}: MenuInputAdornmentProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: BaseSyntheticEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value: string) => {
        onSelect(value)
        setAnchorEl(null);
    };

    return (
        <InputAdornment position="end">
        <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                >
            <ArrowDropDownIcon />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
        >
            {options.map((option: any) => (
            <MenuItem key={option} onClick={()=>handleClose(option)}>
                {option}
            </MenuItem>
            ))}
        </Menu>
        </InputAdornment>
    );
}
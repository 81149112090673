import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Button, Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AddCircle, FileCopy } from "@material-ui/icons";
import CommentIcon from '@material-ui/icons/Comment';
import LaunchIcon from "@material-ui/icons/Launch";
import { navigate } from "gatsby";
import { debounceSearchRender } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { GET_CONTRACTS } from "../../common/models/contracts";
import { GetContracts } from "../../common/models/types/GetContracts";
import { flattenObject } from "../../common/utils";
import { queryLoad, StatusIcon, useModal } from "../../components";
import { activeFilter, creditBalanceFilter, genericFilter, numberColumnFilterNullTo100K, stringColumnFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";
import { EllipsisTooltip, useEllipsisStyles } from "../../components/tooltip";
import { RootState } from "../../state";
import { contractListUpdated } from "../../state/contractSectionReducer";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import { ContractCloneModal } from "./contractCloneModal";
import { ContractCommentList } from "./contractComment";
import { ContractFormModal } from "./contractModal";



interface ContractsProps {
  updateContractList?: boolean;
  dispatch?: Function;
  contractForms: FormStateProps;
}


const ContractList = ({ updateContractList, dispatch, contractForms }: ContractsProps) => {
  const [getAllContracts, { data, called, error, loading, refetch }] = useLazyQuery<GetContracts>(GET_CONTRACTS);  

  const classes = useEllipsisStyles();
  const [commentDrawer, setCommentDrawer] = useState<boolean>(false);  
  const [ContractId, setContractId] = useState();

  const openCommentDrawer = (ContractId: any) => {       
    setContractId(ContractId); 
    setCommentDrawer(true);    
  }

  const columnsMUI = [
    {
      name: "ContractId",
      label: " ",
      options: {
        width: "200px",
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <IconButton
                onClick={() => navigate("/contracts/" + value)}
              >
                <LaunchIcon />
                
              </IconButton>
              <IconButton
                onClick={() => openCommentDrawer(value)}
              >
                <CommentIcon />
                
              </IconButton>
            </>
            
          );
        },
      },
    },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "ContractName",
      label: "Contract Name",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "BuyerName",
      label: "Buyer Name",
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnContractList}`}>
              {value}
            </span>
          </EllipsisTooltip>
        ),
      },
    },
    {
      name: "AccountManagerName",
      label: "Account Manager",
      options: {
        viewColumns: false,
        // filter: true,
        // customFilterListOptions: {
        //   render: (v: any) => `Account Manager: ${v}`,
        // },
        ...genericFilter,
        customBodyRender: (value: any, tableMeta: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnContractList}`}>
              {value}
            </span>
          </EllipsisTooltip>
        ),
      },
    },
    {
      name: "BuyerGroupName",
      label: "Buyer Group",
      options: {
        viewColumns: false,
        ...genericFilter,
        // filter: true,
        // customFilterListOptions: {
        //   render: (v: any) => `Buyer Group: ${v}`,
        // },
      },
    },
    {
      name: "CreditBalance",
      label: "Credit Balance",
      options: {
        viewColumns: false,
        ...creditBalanceFilter,
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        viewColumns: false,
        ...activeFilter,
      },
    },
    {
      name: "VerticalName",
      label: "Vertical",
      options: {
        ...genericFilter,
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub Vertical",
      options: {
        ...genericFilter,
      },
    },
    {
      name: "TcpaType",
      label: "TCPA Type",
      options: {
        ...stringColumnFilter,
      },
    },
    {
      name: "MaxNMatch",
      label: "MaxNMatch",
      options: {
        ...numberColumnFilterNullTo100K,
      },
    },
  ];

  const { Modal, closeModal, openModal } = useModal();

  const ADD_FORM_ID = `contract-add`;

  useEffect(() => {
    getAllContracts();
  }, []);

  useEffect(() => {
    if (data && updateContractList) {
      /* toast.info("Refreshing List"); */
      refetch().finally(() => {
        /* toast.dismiss(); */
        //commenting signalContractRefreshCarriedOut() so contract details will also update once in view
        dispatch && dispatch(contractListUpdated());
      });
    }
  }, [data, updateContractList]);

  const options: any = {
    searchOpen: true,
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    sortOrder: {
      name: 'ContractName',
      direction: 'asc'
    },
    customSearchRender: debounceSearchRender(500),
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box p={1}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<FileCopy />}
                onClick={() => {
                  openModal({
                    title: "Clone Contract",
                    icon: <FileCopy />,
                    content: (
                      <ContractCloneModal
                        data={data?.LDPConfigQueryGroup?.Contract}
                        close={closeModal}
                        refetch={refetch}
                      />
                    ),
                  });
                }}
              >
                Clone Contract
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                startIcon={<AddCircle />}
                onClick={() => {
                  const formProps =
                  {
                    formId: ADD_FORM_ID,
                    formTitle: `Add Contract`,
                    formComponent: <ContractFormModal />,
                    formData: {},
                    formProps: {
                      closeTab: () => dispatch && dispatch(resetForm({ formId: ADD_FORM_ID })),
                    }
                  };
                  dispatch && dispatch(
                    contractForms.formContainers[ADD_FORM_ID]
                      && contractForms.formContainers[ADD_FORM_ID].isLastOpenedDocked ?
                      dockForm(formProps) : openForm(formProps)
                  );
                }}
              >
                Create New
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {queryLoad([!!loading], [error]) || (
          <LDPUIDataTable
            ldpTableId="contract-list-index"
            restoreFilters={true}
            searchAlwaysOpen
            searchPersistentKey={'contract-search'}
            title={<Typography variant='h6'>Contracts {updateContractList && <CircularProgress size={20} />}</Typography>}
            data={data?.LDPConfigQueryGroup?.Contract?.map(obj => flattenObject(obj))}
            columns={columnsMUI}
            options={options}
          />
        )}
      </Grid>
      <Modal />
      <Drawer
        anchor="right"
        open={commentDrawer}
        onClose={()=>setCommentDrawer(false)}
      >
        <ContractCommentList ContractId={ContractId} onClose={()=>setCommentDrawer(false)} />
    </Drawer>
    </Grid>
  );
};

export default connect((state: RootState) => ({
  updateContractList: state.contractSection.updateContractList,
  contractListState: state.contractSection.contractListState,
  contractForms: state.formsSection,
}), null)(ContractList);

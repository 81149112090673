import React, { cloneElement, useEffect, useState } from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { ContentContainer, Layout, PrivateRoute, useModal, queryLoad } from "../components";

import ContractList from "../views/contract/contractList";
import ViewContract from "../views/contract/contractView";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { RootState } from "../state";

import { Typography } from '@material-ui/core';
import { TabProps, tabExist, TabsContainer } from "../components/tabs";
import { updateTabContainers } from "../state/tabsReducer";
import { closedForm, FormDataProps, FormStateProps, triggeredForm } from "../state/formReducer";
import { updateContractMainForms } from "../state/contractSectionReducer";

import { GET_CONTRACTS } from "../common/models/contracts";
import { GetContracts } from "../common/models/types/GetContracts";
import { useLazyQuery } from "@apollo/react-hooks";

interface Props {
  id?: String;
  name?: string;
  contractSaveSignal: boolean;
  dispatch: Function;
  contractTabs: any;
  contractForms: FormStateProps;
  contractMain: any;
}

const TAB_CONTAINER = "contract-list-tabs";

// list of dockable forms in this page
const FORM_IDS = [
  `contract-edit`,
  `contract-add`,
];

const ContractTitle = ({ contractId }: any) => {
  const [getContract, { data, error, loading, called, refetch }] = useLazyQuery<
    GetContracts
  >(GET_CONTRACTS);

  useEffect(() => {
    getContract({
      variables: { where: `ContractId = ${contractId}` },
    });
  }, [contractId]);

  return (
    <>
      {queryLoad([!!loading], [error]) || (
        <div style={{ float: "left", textAlign: "left" }}>
          <Typography variant="inherit" >
            <div style={{
              whiteSpace: "nowrap",
              width: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>{data?.LDPConfigQueryGroup?.Contract[0]?.ContractName}</div>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Contract #({contractId})
          </Typography>
        </div>
      )}
    </>
  )
}

const Contracts = (props: Props & RouteComponentProps) => {
  const { contractTabs, dispatch, contractForms, contractMain, id: contractId } = props;

  /* let { contractId } = props; */
  const { Modal, closeModal, openModal } = useModal();
  const [tabFocus, setTabFocus] = useState<number>(0);
  const [tabsInitialized, setTabsInitialized] = useState(false);
  const [_contractTabs, setContractTabs] = useState<TabProps[]>([]);
  const [_formIds, setFormIds] = useState<string[]>();
  const [formsInitialized, setFormsInitialized] = useState(false);

  const handleCloseTab = (tabs: TabProps[]) => {
    
    dispatch(
      updateTabContainers({
        [TAB_CONTAINER]: {
          tabFocus: (tabs.length - 1),
          tabs: tabs,
        }
      })
    );
    navigate(`${tabs[tabs.length - 1].permalink}`);
  };

  const handleTabChangeFocus = (props: { tabs: TabProps[], focus: number, permalink: string, tabsContainerId: string }) => {
    if (props.permalink) navigate(props.permalink);
    else navigate(props.tabs[props.focus].permalink)
    dispatch(
      updateTabContainers({
        [props.tabsContainerId]: {
          tabFocus: props.focus,
          tabs: props.tabs,
        }
      })
    );
  };

  useEffect(() => {
    if (!contractTabs.tabContainers[TAB_CONTAINER]) {
      //Initialize Redux tabsContainer for contracts
      let initialTabContainerFocus = 0;
      let initialTabs: TabProps[] = [
        {
          title: "Contracts",
          tabId: "contract-list",
          content: <ContractList />,
          permalink: `/contracts/`,
        },
      ];

      if (contractId) {
        initialTabContainerFocus = 1;
        initialTabs = [
          ...initialTabs,
          {
            title: <ContractTitle contractId={contractId} />,
            tabId: `contract-${contractId}`,
            content: <ViewContract contractId={contractId} />,
            closeTab: handleCloseTab,
            permalink: `/contracts/${contractId}`,
          },
        ]
      }

      const initialContractsTabContainer = {
        [TAB_CONTAINER]: {
          tabFocus: initialTabContainerFocus,
          tabs: initialTabs,
        }
      };

      dispatch(updateTabContainers(initialContractsTabContainer));
    } else {
      if (contractTabs.tabContainers[TAB_CONTAINER]) {
        setContractTabs(contractTabs.tabContainers[TAB_CONTAINER].tabs);
        setTabFocus(contractTabs.tabContainers[TAB_CONTAINER].tabFocus);
        setTabsInitialized(true);
      }
    }
  }, [contractId, contractTabs])

  useEffect(() => {
    if (Object.keys(contractMain).length === 0) {
      dispatch(
        updateContractMainForms({
          contractId: `contracts`,
          formIds: [
            `contract-edit`,
            `contract-add`,
          ]
        })
      );
    } else {
      setFormIds(contractMain.formIds);
      setFormsInitialized(true);
    }
  }, [contractMain]);

  useEffect(() => {
    if (isNaN(Number(contractId))) {
      navigate('/contracts/');
      return;
    }

    if (contractId && tabsInitialized) {
      const _tabExist = tabExist(_contractTabs, `contract-${contractId}`);

      if (_tabExist > 0) {
        setTabFocus(_tabExist);
      } else {
        dispatch(updateTabContainers(
          {
            [TAB_CONTAINER]: {
              tabFocus: _contractTabs.length,
              tabs: [
                ..._contractTabs,
                {
                  // title: `Contract #(${contractId})`,
                  title: <ContractTitle contractId={contractId} />,
                  tabId: `contract-${contractId}`,
                  content: <ViewContract contractId={contractId} />,
                  closeTab: handleCloseTab,
                  permalink: `/contracts/${contractId}`,
                },
              ],
            }
          }
        ));
      }
    }

  }, [contractId, tabsInitialized]);

  const handleDocking = (formData: FormDataProps) => {
    if (formData) {
      const _tabExist = tabExist(_contractTabs, formData.formId);
      let _tabs: TabProps[] = (_tabExist > 0) ? _contractTabs?.filter((tab, index) => (index !== _tabExist)) : _contractTabs;

      if (formData.isDocked) {

        closeModal();
        dispatch(updateTabContainers(
          {
            [TAB_CONTAINER]: {
              tabFocus: _tabs?.length,
              tabs: [
                ..._tabs,
                {
                  title: formData.formTitle,
                  tabId: formData.formId,
                  content: (typeof formData.formComponent !== 'undefined' &&
                    cloneElement(
                      formData.formComponent,
                      {
                        ...formData,
                        data: formData.formData,
                        close: formData.formProps.closeTab,
                        isDocked: formData.isDocked,
                      }
                    )
                  ),
                  closeTab: (tabs: TabProps[]) => {
                    handleCloseTab(tabs);
                  },
                },
              ],
            }
          }
        ));
      } else if (formData.isModal) {
        dispatch(updateTabContainers(
          {
            [TAB_CONTAINER]: {
              //tabFocus: (_tabs.length - 1),
              tabFocus: formData?.formProps?.tabFocus ? formData?.formProps?.tabFocus : (tabFocus < _tabs.length) ? tabFocus : (_tabs.length - 1),
              tabs: _tabs,
            }
          }
        ));
        openModal({
          title: formData.formTitle,
          icon: <NoteAddIcon />,
          iconColor: "orange",
          content: (
            typeof formData.formComponent !== 'undefined' &&
            cloneElement(
              formData.formComponent,
              {
                ...formData,
                data: formData.formData,
                close: closeModal,
                isDocked: formData.isDocked,
              }
            )
          ),
          customHeaderButtons: <div />,
        });
      }
    }

    //defrag the form trigger
    dispatch(triggeredForm({ formId: formData.formId }));
  };

  useEffect(() => {
    if (tabsInitialized && formsInitialized && _formIds && contractForms) {
      _formIds.forEach(formId => {
        if (contractForms.formContainers[formId] && contractForms.formContainers[formId].isTriggered) {
          handleDocking(contractForms.formContainers[formId]);
        } else if (contractForms.formContainers[formId] && contractForms.formContainers[formId].isClosed) {
          const _tabExist = tabExist(_contractTabs, formId);
          let _tabs: TabProps[] = (_tabExist > 0) ? _contractTabs?.filter((tab, index) => (index !== _tabExist)) : _contractTabs;

          handleCloseTab(_tabs);
          dispatch(closedForm({ formId: formId }));
        }
      });
    }
  }, [contractForms, tabsInitialized, formsInitialized, _formIds]);

  return (
    <ContentContainer>
      <TabsContainer
        tabs={_contractTabs}
        onCloseTab={setContractTabs}
        tabFocus={tabFocus}
        onChange={handleTabChangeFocus}
        tabsContainerId={TAB_CONTAINER}
      />
      <Modal />
    </ContentContainer>
  );
};

const ContractsWrapper = connect((state: RootState) => ({
  contractSaveSignal: state.contractSection.contractRecordSaved,
  contractTabs: state.tabsSection,
  contractForms: state.formsSection,
  contractMain: state.contractSection.contractMain,
}), null)(Contracts);

export default ({ location }: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute component={ContractsWrapper} path="contracts/" pagetitle="LDP Contracts" />
        <PrivateRoute component={ContractsWrapper} path="contracts/:id" pagetitle="LDP Contracts" />
        <PrivateRoute component={ContractsWrapper} path="contracts/:id/:name" pagetitle="LDP Contracts" />
      </Router>
    </Layout>
  );
};



import gql from "graphql-tag";

export const GET_CONTRACT_LEAD_CAPACITY_CONFIG = gql`
    query GetContractLeadCapacityConfig($where: String){
        LDPConfigQueryGroup {
            GetContractLeadCapacityConfig(limit:9999, where: $where) {
                AffiliateId
                ContractId
                ContractLeadCapacityConfigId
                CreatedDate
                IsActive
                MaxDailyCapacity
                MaxMonthlyCapacity
                MaxWeeklyCapacity
                ModifiedDate
                Notes
                UserId
            }
        }
    }
`;  

export const SAVE_CONTRACT_LEAD_CAPACITY_CONFIG = gql`
    mutation MergeContractLeadCapacityConfig($contractLeadCapacityConfigInput: MergeContractLeadCapacityConfigInputType!){
        LDPConfigMutationGroup {
            MergeContractLeadCapacityConfig(MergeContractLeadCapacityConfigParams : $contractLeadCapacityConfigInput)
        }
    }
`; 
import gql from "graphql-tag";

export const GET_CONTRACT_COMMENT = gql`
    query GetContractComment($where: String){
        LDPConfigQueryGroup {
            ContractComment(limit:9999, where: $where) {
                Comment
                ContractCommentId
                ContractId                
                UserId
                CreatedDate
                ModifiedDate
            }
        }
    }
`;  

export const SAVE_CONTRACT_COMMENT = gql`
    mutation SaveContractComment($contractCommentInput: ContractCommentInputType!, $userId: String) {
        LDPConfigMutationGroup {
            SaveContractComment(ContractCommentParams: $contractCommentInput, UserId: $userId)
        }
    }
`; 

import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import { SAVE_CONTRACT_RESPONSE_PARSING } from "../../common/models/contractResponseParsing";
import { GET_RESPONSE_DISPOSITION } from "../../common/models/responseDisposition";
import { ContractResponseParsingInputType } from "../../types/graphql-global-types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import { GetResponseDisposition } from "../../common/models/types/GetResponseDisposition";
import { queryLoad } from "../../components";
import Autocomplete from "@material-ui/lab/Autocomplete";

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { SaveContractResponseParsing } from "../../common/models/types/SaveContractResponseParsing";

interface ContractParsingFormModalProps {
  data: ContractResponseParsingInputType | null;
  contractId: any;
  action: string;
  close: Function;
  refetch: Function;
}

interface MappedSelectField {
  id: number;
  label: string;
}

const formError = {
  OrderOfOperation: {
    required: {
      value: true,
      message: "Order Of Operation is required.",
    },
  },
  ResponseDispositionId: {
    required: {
      value: true,
      message: "Response Disposition is required.",
    },
  },
  ResponseValue: {
    required: {
      value: true,
      message: "Response Value is required.",
    },
  },
};

export const ContractResponseParsingModal = ({
  action,
  contractId,
  data,
  close,
  refetch,
}: ContractParsingFormModalProps) => {
  const { register, handleSubmit, errors, formState: {
    isDirty
  } } = useForm<
  ContractResponseParsingInputType
  >();

  const classes = useStyles();

  const pageTitle =
    action == "create" ? "Add New Response Mapping" : "Edit Response Mapping";

  const [
    getResponseDisposition,
    {
      data: responseDispositionData,
      error: responseDispositionError,
      loading: responseDispositionLoading,
    },
  ] = useLazyQuery<GetResponseDisposition>(GET_RESPONSE_DISPOSITION);

  const [saveContractResponseParsing] = useMutation<SaveContractResponseParsing>(SAVE_CONTRACT_RESPONSE_PARSING);

  const onSubmit = async (dataInput: ContractResponseParsingInputType) => {

    let contractResponseParsingData = {
      ...dataInput,
      ResponseDispositionId: mappedResponseDisposition?.find(
        (responseDisposition: MappedSelectField) => responseDisposition?.label === dataInput.ResponseDispositionId
      )?.id,
    };

    if(!contractResponseParsingData.ContractResponseParsingId || contractResponseParsingData.ContractResponseParsingId === '0'){
      if(!isPing && !isPost){
        toast.error("Must specify if this is PING, POST or both.");
      } else {
        isPing && await saveContractResponseParsing({ variables: {
          contractResponseParsingData: { 
            ...contractResponseParsingData,
            IsPing: true,
            UserId: '',
          }
        } }).then(
          () => {
            toast.success("Response Mapping as PING created successfully.");
          }
        ).catch((error) => {
          console.log('saveContractResponseParsing', error);
        });

        isPost && await saveContractResponseParsing({ variables: {
          contractResponseParsingData: { 
            ...contractResponseParsingData,
            IsPing: false,
            UserId: '',
          }
        } }).then(
          () => {
            toast.success("Response Mapping as POST created successfully.");
          }
        ).catch((error) => {
          console.log('saveContractResponseParsing', error);
        });

        refetch();
        close();
      }
    } else {
      saveContractResponseParsing({ variables: { contractResponseParsingData: { ...contractResponseParsingData, UserId: '' } } }).then(
        () => {
          if (contractResponseParsingData?.ContractResponseParsingId > 0)
            toast.success("Response Mapping updated successfully.");
          else toast.success("Response Mapping created successfully.");

          refetch();
          close();
        }
      ).catch((error) => {
        console.log('saveContractResponseParsing', error);
      });
    }

  };

  const [loading, setLoading] = useState(true);
  const [mappedResponseDisposition, setMappedResponseDisposition] = useState<MappedSelectField[]>();
  const [selectedResponseDisposition, setSelectedResponseDisposition] = useState<MappedSelectField | null>();

  const [isPing, setAsPing] = useState(true);
  const [isPost, setAsPost] = useState(true);

  useEffect(() => {
    getResponseDisposition({
      variables: {
        where: "HttpStatusCode = Null"
      }
    });
  }, []);

  useEffect(() => {
    if (responseDispositionData) {
      setMappedResponseDisposition(
        responseDispositionData?.LDPConfigQueryGroup?.ResponseDisposition?.map(x => {
          return { id: x?.ResponseDispositionId, label: `${x?.ResponseDispositionName} - ${x?.ResponseDispositionId}` };
        })
      );
    }
  }, [responseDispositionData]);

  useEffect(() => {
    setSelectedResponseDisposition(
      mappedResponseDisposition?.find(
        (responseDisposition: MappedSelectField) => responseDisposition?.id === data?.ResponseDispositionId
      ) || null
    );
    setLoading(false);
  }, [mappedResponseDisposition]);

  return (
    <Paper className={classes.contrainer}>
      {queryLoad([!!loading, !!responseDispositionLoading], [responseDispositionError]) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            hidden
            inputRef={register}
            name="ContractResponseParsingId"
            defaultValue={data?.ContractResponseParsingId ?? 0}
          />
          <TextField
            hidden
            inputRef={register}
            name="ContractId"
            defaultValue={data?.ContractId ?? contractId}
          />
          <TextField
            hidden
            inputRef={register}
            name="UserId"
            defaultValue={data?.UserId ?? "Test User"}
          />
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                inputRef={register(formError.OrderOfOperation)}
                error={errors.OrderOfOperation && true}
                helperText={
                  errors.OrderOfOperation && errors.OrderOfOperation?.message
                }
                name="OrderOfOperation"
                label="Order Of Operation"
                defaultValue={data?.OrderOfOperation ?? ""}
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: 
                    <InputAdornment position="start">
                      <Tooltip title={"The order (low to high) that we want to evaluate in."}>
                      <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="response-disposition-input"
                options={mappedResponseDisposition || []}
                getOptionLabel={option => option.label}
                value={selectedResponseDisposition}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedResponseDisposition(newValue);
                }}
                renderInput={params => {
                  params.InputProps.startAdornment = ( 
                      <InputAdornment position="start">
                        <Tooltip title={"Key into a table of Response Dispositions (see below)."}>
                        <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>);

                  return (<TextField
                    {...params}
                    label="Response Disposition"
                    variant="outlined"
                    error={errors.ResponseDispositionId ? true : false}
                    helperText={errors.ResponseDispositionId && errors.ResponseDispositionId?.message}
                    inputRef={register(formError.ResponseDispositionId)}
                    name="ResponseDispositionId"
                  />);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(formError.ResponseValue)}
                error={errors.ResponseValue && true}
                helperText={
                  errors.ResponseValue && errors.ResponseValue?.message
                }
                name="ResponseValue"
                label="Response Value"
                defaultValue={data?.ResponseValue ?? ""}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title={"The value we’re looking for in the response (literal value)."}>
                      <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.IsActive ?? false}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              { 
                !data?.ContractResponseParsingId ? 
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPing}
                          name="IsPing"
                          color="primary"
                          onChange={(evt) => {
                            setAsPing(!isPing);
                          }}
                        />
                      }
                      label={<>
                      {"Ping"}
                        <Tooltip title={"Boolean indicating this is a PING response."}>
                          <HelpOutlineIcon fontSize="small" />
                        </Tooltip>
                      </>}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPost}
                          name="IsPost"
                          color="primary"
                          onChange={(evt) => {
                            setAsPost(!isPost);
                          }}
                        />
                      }
                      label={<>
                      {"Post"}
                        <Tooltip title={"Boolean indicating this is a POST response."}>
                          <HelpOutlineIcon fontSize="small" />
                        </Tooltip>
                      </>}
                    />
                  </>
                  : 
                  <FormControlLabel
                    control={
                      <Switch
                        inputRef={register}
                        defaultChecked={data?.IsPing ?? true}
                        name="IsPing"
                        color="primary"
                      />
                    }
                    label={<>
                    {"Ping"}
                      <Tooltip title={"Boolean indicating this is a PING response (or not)."}>
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </>}
                  />
              }
              
            </Grid>
            <Grid item xs={12} sm={6}> 
              <FormControlLabel
                  control={
                    <Switch
                      inputRef={register}
                      defaultChecked={data?.IsWordBoundaryMatch ?? false}
                      name="IsWordBoundaryMatch"
                      color="primary"
                    />
                  }
                  label={
                  <>
                    {"Word Boundary Match"}
                    <Tooltip title={"If it must be an exact word. For example ‘success’ would match in ‘successful’ unless this is set to true."}>
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </>}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={data?.ResponseValueIsCaseSensitive ?? false}
                    name="ResponseValueIsCaseSensitive"
                    color="primary"
                  />
                }
                label={
                  <>
                    {"Response Value Is Case Sensitive"}
                    <Tooltip title={"The value we’re looking for must meet this case (true) or not (false)"}>
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                    </>
                  }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={!isDirty}
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      margin: "0",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

import { DateTime } from "luxon";


export const convertMititaryTimeTo12HourFormat = (time: string) => {
    if(!time){
        return '';
    }
    const militaryTimeArr = time.split(":");
    let hours = parseInt(militaryTimeArr[0]);
    const minutes = militaryTimeArr[1];
    let seconds = "00";
    let ampm = "AM";
  
    if (militaryTimeArr.length === 3) {
      seconds = militaryTimeArr[2];
    }
  
    if (hours === 0) {
      hours = 12;
    } else if (hours >= 12) {
      ampm = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }
  
    // Add a leading zero if the hour is less than 10
    const formattedHours = String(hours).padStart(2, "0");
  
    return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
  };
  
  export const convert12HourFormatToMilitaryTime = (time12h: string) => {
    const [time, ampm] = time12h.split(" ");
  
    const [hours, minutes, seconds] = time.split(":").map(Number);
    let militaryHours = hours;
  
    if (ampm === "PM" && hours !== 12) {
      militaryHours += 12;
    } else if (ampm === "AM" && hours === 12) {
      militaryHours = 0;
    }
  
    const militaryTime = `${String(militaryHours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return militaryTime;
  };
  
  export const toggleAMPM = (time: string, targetAMPM: string) => {
    const [timePart, currentAMPM] = time.split(" ");
  
    if (currentAMPM === targetAMPM) {
      return time; // No need to toggle if the target AMPM is the same as the current one
    }
  
    return `${timePart} ${targetAMPM}`;
  };
  
  export const getCurrentTime = (type: string) => {
    const currentDate = new Date();
    if (type === "EndTime") {
      currentDate.setHours(currentDate.getHours() + 1);
    }
  
    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const seconds = currentDate.getSeconds();
  
    // Determine AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";
  
    // Convert to 12-hour format
    hours %= 12;
    hours = hours || 12; // 12 should be displayed as 12, not 0
  
    // Format with leading zeros
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${ampm}`;
    return formattedTime;
  };

  export const getCurrentTime24HourFormat = (type: string) => {
    const currentDate = new Date();
    if (type === "EndTime") {
        currentDate.setHours(currentDate.getHours() + 1);
    }
  
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
  
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
  }

  export const compareTimesV2 = (time1: string, time2: string) => {
    const fmt = 'yyyy-MM-dd hh:mm:ss a'

    // Parse the input times into Date objects (2023-01-01 is only used to create base Date)
    const date1 = DateTime.fromFormat("2023-01-01 " + time1, fmt);
    const date2 = DateTime.fromFormat("2023-01-01 " + time2, fmt);
  
    // Compare the two times
    if (date1 < date2) {
      return true;
    } else if (date1 > date2) {
      return false;
    } else {
      return false;
    }
  }
  
  export const compareTimes = (time1: string, time2: string) => {
    const [time1Str, ampm1] = time1.split(" ");
    const [hours1, minutes1, seconds1] = time1Str.split(":").map(Number);
    const isPM1 = ampm1 === "PM";
  
    const [time2Str, ampm2] = time2.split(" ");
    const [hours2, minutes2, seconds2] = time2Str.split(":").map(Number);
    const isPM2 = ampm2 === "PM";
  
    const date1 = new Date();
    date1.setHours(
      hours1 + (isPM1 && hours1 !== 12 ? 12 : 0),
      minutes1,
      seconds1
    );
  
    const date2 = new Date();
    date2.setHours(
      hours2 + (isPM2 && hours2 !== 12 ? 12 : 0),
      minutes2,
      seconds2
    );
  
    // Compare the two dates
    if (date1 < date2) {
      return true;
    } else if (date1 > date2) {
      return false;
    } else {
      return false;
    }
  };

  export const isValidTimeFormat = (time: string): boolean => {
    const timePattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]:[0-5][0-9] (AM|PM)$/;

    if (!timePattern.test(time)) {
      return false;
    }

    return true;
  };
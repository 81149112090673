import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
} from "@material-ui/core";
import { SAVE_CONTRACT_CAPACITY, UPDATE_MULTI_CONTRACT_CAPACITY_PRICE } from "../../common/models/contractCapacity";
import { ContractCapacityInputType, ProcMergeContractCapacityPriceInput } from "../../types/graphql-global-types";
import { GetContractCapacity_LDPConfigQueryGroup_ContractCapacity as ContractCapacity } from "../../common/models/types/GetContractCapacity";

import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";

interface ContractCapacity247FormProps {
  data: ContractCapacity | any;
  close: Function;
  refetch: Function;
}

export const ContractCapacity247Form = ({
  data,
  close,
  refetch,
}: ContractCapacity247FormProps) => {

  const [saveContractCapacity] = useMutation(SAVE_CONTRACT_CAPACITY);
  const { register, handleSubmit, watch, errors, control, setError } = useForm();
  const classes = useStyles();
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (data: any) => {
    setDisableButtons(true);

    let ContractCapacity:Array<ContractCapacityInputType> = new Array();
    dayNames.forEach((day:string)=>{
      ContractCapacity.push({
        ...data,
        StartTime: "00:00:00",
        EndTime: "23:59:59",
        ScheduleDay: day,
        Notes: '',
        UserId: '',
      })
    });

    saveContractCapacity({
      variables: {
        contractCapacityInput: ContractCapacity
      }
    }).then((response) => {
      // evaluate responses here
      let allSuccess = true;
      let errorMsg = '';
      (response.data?.LDPConfigMutationGroup?.SaveContractCapacity ?? []).forEach((msg:string) => {
        allSuccess &&= (msg === 'Success');
        if(!allSuccess && errorMsg === ''){
          errorMsg = msg;
        }
      });

      if(allSuccess){
        toast.success(`24/7 Contract Capacity created successfully.`);
        refetch();
        close();
      } else {
        toast.error(`Some items failed to save: ${errorMsg}`);
      }

      setDisableButtons(false);
      
    }).catch(({errors})=>{      
      setDisableButtons(false);
      toast.error("Unexpected error!");
    });
  };

  const ContractCapacityConstraints = {
    MaxCapacity: {
      required: {
        value: true,
        message: "Max Capacity is required.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0"
      },
    },
    Price: {
      required: {
        value: true,
        message: "Price Floor is required.",
      },
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Price Floor value."
      },
      min: {
        value: 0,
        message: "Minimum value is 0"
      },
      max: {
        value: 100,
        message: "Maximum value is 100"
      }
    },
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractCapacityId"
              defaultValue={data?.ContractCapacityId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
            <TextField
              inputRef={register(ContractCapacityConstraints.MaxCapacity)}
              error={errors.MaxCapacity && true}
              helperText={errors.MaxCapacity && errors.MaxCapacity?.message}
              name="MaxCapacity"
              label="Max Capacity"
              defaultValue={""}
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              inputRef={register(ContractCapacityConstraints.Price)}
              error={errors.Price && true}
              helperText={errors.Price && errors.Price?.message}
              name="Price"
              label="Price Floor"
              defaultValue={""}
              variant="outlined"
              type="number"
              inputProps={{
                step: "0.01"
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={true}
                  name="IsPing"
                  color="primary"
                />
              }
              label="Is Ping"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

import React, { BaseSyntheticEvent, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  withStyles,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Paper,
  Typography,
  Divider,
  FormControlLabel,
  TextField,
  Switch,
  FormControl,
  FormHelperText,
  Tooltip,
  Zoom,
  InputLabel,
  MenuList,
  MenuItem,
  Select,
  IconButton,
  Box,
  Container,
} from "@material-ui/core";
import { SAVE_CONTRACT_REQUEST_SCHEMA } from "../../common/models/contractRequestSchema";
import { ContractRequestSchemaInputType } from "../../types/graphql-global-types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import SaveIcon from "@material-ui/icons/Save";
import { MenuInputAdornment } from "../../components/menuInputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FillSpinner as Loader } from "react-spinners-kit";
import Editor from "@monaco-editor/react";
import CloseIcon from '@material-ui/icons/Close';
import TabIcon from '@material-ui/icons/Tab';
import LaunchIcon from '@material-ui/icons/Launch';
import { HelpTip } from "../../components/helpTip";

import { GetTableDefinition } from "../../common/models/types/GetTableDefinition";
import { GET_TABLE_DEFINITION } from "../../common/models/tableDefinition";
import { KeyValueEditor } from "../../components/keyValueEditor";
import { GET_TRANSFORM_CODES } from "../../common/models/transformCode";
import { GetTransformCodes } from "../../common/models/types/GetTransformCodes";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { signalContractRequestBodySaved } from "../../state/contractRequestReducer";
import { queryLoad, useConfirmation } from "../../components";
import { FormStateProps, toggleForm } from "../../state/formReducer";
import { escapeRegexChars } from "../../common/utils/regex";
import { Alert, AlertTitle } from "@material-ui/lab";

interface ContractFormModalProps {
  data: ContractRequestSchemaInputType | null;
  contractId: any;
  action: string;
  close: Function;
  dispatch: Function;
  isDocked?: boolean;
  formId: string;
  contractForm: FormStateProps;
  tableDefinitionSchema: string;
  tableDefinitionName: string;
  allowedSchemaType?: {
    ping: boolean;
    post: boolean;
  }
}

const ContractRequestSchemaFormModal = ({
  action,
  contractId,
  tableDefinitionSchema,
  tableDefinitionName,
  data,
  close,
  dispatch,
  isDocked,
  formId,
  contractForm,
  allowedSchemaType,
}: ContractFormModalProps) => {
  const { register, handleSubmit, setValue, errors, control, reset } = useForm<ContractRequestSchemaInputType>();
  const classes = useStyles();
  const confirm = useConfirmation();

  const [saveContract] = useMutation(SAVE_CONTRACT_REQUEST_SCHEMA);

  const callSaveContract = (contractRequestSchema:ContractRequestSchemaInputType) => {
    saveContract({ variables: { contractRequestSchema: { ...contractRequestSchema, UserId: '' } } }).then(
      (response: any) => {
        if (data?.ContractRequestSchemaId > 0)
          toast.success("Request Body updated successfully.");
        else toast.success("Request Body created successfully.");

        dispatch(signalContractRequestBodySaved());
        if(!isDocked) close();
      }
    ).catch((error) => {
      console.log('saveContract', error);
    });
  }

  const onSubmit = (contractRequestSchema: ContractRequestSchemaInputType) => {
    //console.log("contractRequestSchema", contractRequestSchema);
    var requestSchemaData: any = contractRequestSchema;
    requestSchemaData.RequestSchema = code;
    if (contractRequestSchema.IsUrlEncodedRequest === true) {
      requestSchemaData.Encoding = "";
    }    

    if (contractRequestSchema?.ContractRequestSchemaId > 0){
      confirm.useModal(() => {
        callSaveContract(contractRequestSchema);
      }, {
        title: 'Update Confirmation',
        description: 'Updating  will deactivate the contract and requires a test before reactivating. Are you sure you want to update?',
        confirm: 'Update',
        cancel: 'Cancel'
      });
    } else {
      callSaveContract(contractRequestSchema);
    }
  };

  const formatContractRequestBodyData = () => {
    const dataInput = control.getValues();

    return dataInput;
  };

  const [schemaEncoding, setSchemaEncoding] = useState(data?.Encoding ?? "");
  const [urlEncoded, setUrlEncoded] = useState<boolean>(data?.IsUrlEncodedRequest ?? false);

  const ContractRequestConstraints = {
    ContractRequestSchemaName: {
      required: {
        value: true,
        message: "Contract Request Body Name is required.",
      },
    },
    // AuthHeaderSchema: {
    //   required: {
    //     value: false,
    //     message: "Auth Header Schema is required.",
    //   },
    // },
    // AuthHeaderValue: {
    //   required: {
    //     value: false,
    //     message: "Auth Header Value is required.",
    //   },
    // },
    Encoding: {
      required: {
        value: true,
        message: "Encoding is required.",
      },
    },
    HttpVerb: {
      required: {
        value: true,
        message: "Http Action is required.",
      },
    },
    PostUrl: {
      required: {
        value: true,
        message: "PostUrl is required.",
      },
    },
    RequestSchema: {
      required: {
        value: true,
        message: "Request Body is required.",
      },
    },
    TimeoutSeconds: {
      required: {
        value: true,
        message: "Timeout Seconds is required.",
      },
      pattern: {
        value: /^\d{1,3}$/,
        message: "Timeout value is not valid.",
      },
      min: {
        value: 0,
        message: "Min timeout allowed is 0 seconds.",
      },
      max: {
        value: 180,
        message: "Max timeout allowed is 180 seconds.",
      }
    },
  };

  const httpVerbOptions = [
    {
      label: "POST"
    },
    {
      label: "GET"
    },
    {
      label: "PUT"
    },
    {
      label: "PATCH"
    },
  ];

  const defaultCode: any = {
    "json": `{
      "sample": "json"
    }`,
    "xml": `<?xml version="1.0" encoding="ISO-8859-1"?>  
    <note>  
      <to>Tove</to>  
      <from>Jani</from>  
      <heading>Reminder</heading>  
      <body>Don't forget me this weekend!</body>  
    </note>`,
    "html": `<html>
    <head>
      <title>HTML Sample</title>
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <style type="text/css">
        h1 {
          color: #CCA3A3;
        }
      </style>
      <script type="text/javascript">
        alert("I am a sample...");
      </script>
    </head>
    <body>
      <h1>Heading No.1</h1>
      <input disabled type="button" value="Click me" />
    </body>
    </html>`,
  }

  const [httpVerb, setHttpVerb] = useState<any>(httpVerbOptions?.find((it: any) => it?.label === data?.HttpVerb) || httpVerbOptions[0]);

  const [code, setCode] = useState<string | undefined>("");
  const [theme, setTheme] = useState("vs-dark");
  const [language, setLanguage] = useState("text");
  const [searchField, setSearchField] = useState<string>("");
  const [searchTransform, setSearchTransform] = useState<string>("");
  const [source, setSource] = useState<string>("field-name");
  const [searchPattern, setSearchPattern] = useState<RegExp>(/.+/);
  const [formData, setFormData] = useState<any>(data);
  const [formDataLoading, setFormDataLoading] = useState<boolean>(true);

  const editorRef = useRef(null);
  const handleEditorDidMount = (editor: any) => {
    editorRef.current = editor;
  }

  const urlParamRef = useRef(null);
  const handleUrlParamDidMount = (editor: any) => {
    urlParamRef.current = editor;
  }

  const pasteSource = (item: any) => {
    //console.log(item );
    if (language === "url parameter") {
      //setCode(`${code || ""}${(code || "").trim() === '' ? '' : '&'}${item.ColumnName}=`);
      if (source === 'field-name') {
        urlParamRef.current?.setValue(`#Source.${item.ColumnName}#`);
      } else if (source === 'transform-code') {
        urlParamRef.current?.setValue(`#Transform.[FIELDNAME].${item.TransformCodeName}#`);
      }
    } else {
      if (source === 'field-name') {
        editorRef?.current?.trigger('keyboard', 'type', { text: `#Source.${item.ColumnName}#` });
      } else if (source === 'transform-code') {
        editorRef?.current?.trigger('keyboard', 'type', { text: `#Transform.[FIELDNAME].${item.TransformCodeName}#` });
      }
    }

  }

  const handleEditorChange = (code: string | undefined, event: any) => {
    setCode(code);
  }

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  }

  const toggleLanguage = (language: string) => {
    setLanguage(language);
    if (!code) {
      setCode(defaultCode[language]);
    }
  }

  const toggleEditor = (isUrlEncoded:boolean, encoding:string) => {
    if(isUrlEncoded){
      setLanguage("url parameter");
    } else {
      if(encoding === "application/json"){
        setLanguage("json");
      } else if(encoding === "application/xml" || encoding === "application/soap+xml"){
        setLanguage("xml");
      } else {
        setLanguage("text");
      }
    }
  }

  useEffect(()=>{
    if(contractForm.formContainers[formId]){
      if(!formData){
        setFormData(contractForm.formContainers[formId].formData);
      }
    }
  },[contractForm]);

  useEffect(() => {
    if (formData) {
      setSchemaEncoding(formData?.Encoding || "");
      setUrlEncoded(formData?.IsUrlEncodedRequest);
      const checkXML = formData?.RequestSchema?.includes("<?xml") ?? false;
      setCode(formData?.RequestSchema ?? "");       
      toggleEditor(checkXML ? false : formData?.IsUrlEncodedRequest, formData?.Encoding || "");

      setHttpVerb(httpVerbOptions?.find((it: any) => it?.label === formData?.HttpVerb) || httpVerbOptions[0]);
      setFormDataLoading(false);
    }
  }, [formData]);

  useEffect(() => {
    if (searchField !== "") {
      setSearchPattern(new RegExp(escapeRegexChars(searchField), "gi"));
    } else {
      setSearchPattern(/.+/);
    }
  }, [searchField]);

  const [getTableDefinition, { data: tableDefinitionData }] = useLazyQuery<GetTableDefinition>(GET_TABLE_DEFINITION);
  useEffect(() => {
    if (!tableDefinitionData) {
      getTableDefinition({
        variables: {
          schema: tableDefinitionSchema,
          name: tableDefinitionName
        }
      });
    }
  }, [tableDefinitionData]);


  const [
    getTransformCodes,
    { data: transformCodeData },
  ] = useLazyQuery<GetTransformCodes>(GET_TRANSFORM_CODES);

  useEffect(() => {
    if (!transformCodeData) {
      getTransformCodes({
        variables: {
          where:  `BuyerId = ${data?.BuyerId} or BuyerId = null`
        }
      });
    }
  }, [transformCodeData]);

  useEffect(() => {
    setSearchPattern(/.+/);
  }, []);

  return (
    <Paper className={classes.container} 
      style={{
        overflowY: `${isDocked ? 'hidden' : 'visible'}`,
        height: `${isDocked ? 'calc(100vh - 182px)' : 'calc(100vh - 210px)'}`
      }}
    >
      {(!allowedSchemaType?.ping || !allowedSchemaType?.post) &&
        <Alert severity="warning" style={{ width: "100%" }}>
          <AlertTitle><strong>Note: 2 {!allowedSchemaType?.ping ? 'Ping' : 'Post'} entries exists</strong></AlertTitle>
          Only {allowedSchemaType?.ping ? 'Ping' : 'Post'} request schema can be added
        </Alert>
      }
      {!isDocked && 
        <div style={{position: "absolute", top: "0px", right:"15px", marginTop: "-86px"}}>
          <Tooltip title="Dock to tab">
            <IconButton aria-label="settings"
              onClick={() =>{
                  dispatch(toggleForm({formId: formId, formData: {...formatContractRequestBodyData(), BuyerId: formData?.BuyerId}}));
                }
              }
            >
              <TabIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton aria-label="settings"
              onClick={() => {
                close();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      {isDocked &&
        <div style={{textAlign: "right", marginRight: "10px"}}>
          <Tooltip title="Popup">
            <IconButton aria-label="settings"
              onClick={() => {
                  dispatch(toggleForm({formId: formId, formData: {...formatContractRequestBodyData(), BuyerId: formData?.BuyerId}}));
                }
              }
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </div>
      }
      {queryLoad([!!formDataLoading],[]) || (
        <div style={{ height: "100%", overflow: "hidden", position:"relative"}}>
          <form className={classes.root} onSubmit={handleSubmit(onSubmit)} style={{height:"100%"}}>
            <Grid className={classes.mainGrid} container style={{height:"100%"}} >
              <Grid container item xs={12} sm={3} alignContent="flex-start">
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Source</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={source}
                    onChange={(e: BaseSyntheticEvent) => {
                      setSource(e.target.value);
                    }}
                    label="Select Source"
                  >
                    <MenuItem value="field-name" selected>Field Name</MenuItem>
                    <MenuItem value="transform-code">Transform Code</MenuItem>
                  </Select>
                </FormControl>
                <Grid item xs={12} hidden={source !== 'transform-code'}>
                  <TextField
                    label="Search Transform Code"
                    variant="outlined"
                    value={searchField}
                    onChange={(e: BaseSyntheticEvent) => {
                      setSearchField(e.target.value);
                    }}
                  />
                  <Divider />
                  <MenuList className={classes.menuItemStyle}>
                    {transformCodeData?.LDPConfigQueryGroup?.TransformCode?.filter((it) => {
                      if (searchField === "")
                        return true;

                      //return (it?.ColumnName?.substr(0, searchField.length).toLowerCase() === searchField.toLowerCase())
                      return it?.TransformCodeName && it?.TransformCodeName?.match(searchPattern); //searchPattern.test(it?.ColumnName);
                    }).map((it: any) => ([
                      <Divider />,
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{it.TransformCodeName}</Typography>
                          </React.Fragment>
                        }
                        placement="left"
                      >
                        <MenuItem onClick={() => pasteSource(it)}>{it.TransformCodeName}</MenuItem>
                      </HtmlTooltip>
                    ]))}
                  </MenuList>
                </Grid>

                <Grid item xs={12} hidden={source !== 'field-name'}>
                  <TextField
                    label="Search Field Name"
                    variant="outlined"
                    value={searchField}
                    onChange={(e: BaseSyntheticEvent) => {
                      setSearchField(e.target.value);
                    }}
                  />
                  <Divider />
                  <MenuList className={classes.menuItemStyle}>
                    {tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.filter((it) => {
                      if (searchField === "")
                        return true;

                      //return (it?.ColumnName?.substr(0, searchField.length).toLowerCase() === searchField.toLowerCase())
                      return it?.ColumnName && it?.ColumnName?.match(searchPattern); //searchPattern.test(it?.ColumnName);
                    }).map((it: any) => ([<Divider />, <MenuItem onClick={() => pasteSource(it)}>{it.ColumnName}</MenuItem>]))}
                  </MenuList>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9} style={{height:"100%", position: "relative"}}>
                <Box width="100%" height="100%" padding={2} style={{overflowY:"scroll", position:"relative", height:"100%", paddingBottom:"60px"}} >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        hidden
                        inputRef={register}
                        name="ContractRequestSchemaId"
                        defaultValue={formData?.ContractRequestSchemaId ?? 0}
                      />
                      <TextField
                        required
                        hidden
                        inputRef={register}
                        name="ContractId"
                        defaultValue={formData?.ContractId}
                      />
                      <TextField
                        required
                        hidden
                        inputRef={register}
                        name="UserId"
                        defaultValue={formData?.UserId ?? "Test User"}
                      />
                      <TextField
                        inputRef={register(ContractRequestConstraints.ContractRequestSchemaName)}
                        error={errors.ContractRequestSchemaName && true}
                        helperText={
                          errors.ContractRequestSchemaName &&
                          errors.ContractRequestSchemaName?.message
                        }
                        name="ContractRequestSchemaName"
                        label="Request Body Name"
                        defaultValue={formData?.ContractRequestSchemaName ?? ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        inputRef={register}
                        name="Encoding"
                        label="Encoding"
                        defaultValue=""
                        value={schemaEncoding}
                        variant="outlined"
                        onChange={(e) => {
                          if (typeof e.target.value === 'string') {
                            setValue("Encoding", e.target.value);
                            setSchemaEncoding(e.target.value);
                          }
                        }}
                        InputProps={{
                          endAdornment:
                            <MenuInputAdornment options={[
                              "application/json",
                              "application/xml",
                              "application/soap+xml"
                            ]} onSelect={(value: any) => {
                              if (typeof value === 'string') {
                                setValue("Encoding", value);
                                setSchemaEncoding(value);
                                toggleEditor(urlEncoded, value);
                              }

                            }} />
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        id="http-verb-id"
                        options={httpVerbOptions}
                        value={httpVerb}
                        getOptionLabel={option => option.label}
                        getOptionSelected={option => option.label}
                        onChange={(event: any, newValue: any) => {
                          setHttpVerb(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            inputRef={register(ContractRequestConstraints.HttpVerb)}
                            helperText={errors.HttpVerb && errors.HttpVerb?.message}
                            error={!!errors.HttpVerb}
                            name="HttpVerb"
                            label="HTTP Action"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register(ContractRequestConstraints.PostUrl)}
                        error={errors.PostUrl && true}
                        helperText={errors.PostUrl && errors.PostUrl?.message}
                        name="PostUrl"
                        label="PostUrl"
                        defaultValue={formData?.PostUrl ?? ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        inputRef={register(ContractRequestConstraints.TimeoutSeconds)}
                        error={errors.TimeoutSeconds && true}
                        helperText={
                          errors.TimeoutSeconds && errors.TimeoutSeconds?.message
                        }
                        name="TimeoutSeconds"
                        label="Timeout Seconds"
                        defaultValue={formData?.TimeoutSeconds ?? ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            inputRef={register}
                            defaultChecked={formData?.IsActive ?? false}
                            name="IsActive"
                            color="primary"
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            inputRef={register}
                            defaultChecked={formData?.IsPing ?? allowedSchemaType?.ping ? true : false}
                            name="IsPing"
                            color="primary"
                            disabled={!allowedSchemaType?.ping || !allowedSchemaType?.post}
                          />
                        }
                        label="IsPing"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            inputRef={register}
                            defaultChecked={formData?.IsUrlEncodedRequest ?? false}
                            onChange={(evt, checked) => {
                              setUrlEncoded(checked);
                              toggleEditor(checked, schemaEncoding);
                            }}
                            name="IsUrlEncodedRequest"
                            color="primary"
                          />
                        }
                        label="Url Encoded Request"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        hidden
                        inputRef={register(ContractRequestConstraints.RequestSchema)}
                        error={errors.RequestSchema && true}
                        helperText={errors.RequestSchema && errors.RequestSchema?.message}
                        name="RequestSchema"
                        label="Request Body"
                        value={code}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl error>
                        <Typography variant="h5" component="h5">
                          Request Body
                        {/* <Tooltip className={classes.helpIcon} TransitionComponent={Zoom} placement="right" title="Press Shift+Alt+F to Auto Format">
                            <EmojiObjectsOutlinedIcon color="primary" fontSize="large" />
                          </Tooltip> */}
                          <HelpTip title={"Press Shift+Alt+F to Auto Format."} size="small" />
                        </Typography>
                        <FormHelperText>
                          {errors.RequestSchema && errors.RequestSchema?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={1} alignItems="center">

                    </Grid>
                    <Grid item xs={11} sm={5}>
                      <Autocomplete
                        options={['Text', 'HTML', 'JSON', 'XML', 'URL Parameter']}
                        value={language}
                        onChange={(event: any, newValue: any) => {
                          if(newValue){
                            toggleLanguage(newValue.toLowerCase());
                            /* if(urlEncoded){
                              toggleLanguage("url parameter");
                            } else {
                              toggleLanguage(newValue.toLowerCase());
                            } */
                          }
                          
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            name="language"
                            label="Language"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {
                        language === "url parameter" ? (
                          <KeyValueEditor
                            ref={urlParamRef}
                            onChange={(dataMap) => {
                              
                              const params:string[] = [];
                              Object.keys(dataMap).forEach(dataKey => {
                                params.push(`${dataKey}=${dataMap[dataKey]}`);
                              });

                              setCode(params.join("&"));
                            }}
                            keyValuePairs={code || ""} />
                        ) : (
                          <Box width="100%">
                            <Editor
                              height="25vh" // By default, it fully fits with its parent
                              theme={theme}
                              language={language}
                              loading={<div>loading...</div>}
                              value={code}
                              defaultValue={code}
                              onChange={handleEditorChange}
                              onMount={handleEditorDidMount}
                              options={{ lineNumbers: "on", "wordWrap": "on", }}
                            />
                          </Box>
                        )
                      }</Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        type="button"
                        size="large"
                        fullWidth
                        onClick={() => close()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                        size="large"
                        fullWidth
                        onClick={(e) => {
                          confirm.useModal(() => {
                            reset();
                            setCode(data?.RequestSchema || "");
                          }, {
                            title: 'Request Body Form',
                            description: 'Are you sure that you want to reset this form?',
                            confirm: 'Reset',
                            cancel: 'Cancel'
                          });
                          e.preventDefault();
                        }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        fullWidth
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      <confirm.Modal />
    </Paper>
  );
};

export default connect((state: RootState) => ({
  contractRequestBodyRecordSaved: state.contractRequestSection.contractRequestBodyRecordSaved,
  contractTabs: state.tabsSection,
  contractForm: state.formsSection,
}), null)(ContractRequestSchemaFormModal);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    menuItemStyle: { maxHeight: "600px", overflow: "hidden auto" },
    container: {
      textAlign: "left",
      position: "relative",
    },
    mainGrid: {
      paddingX: "20px",
    },
    helpIcon: {
      verticalAlign: "middle",
      cursor: "help"
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

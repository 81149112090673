import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { DateTime, Info } from "luxon";
import LDPUIDataTable from "../../components/LDPUIDataTable";

import { DELETE_CONTRACT_CAPACITY_BY_IDS, GET_CONTRACT_CAPACITY } from "../../common/models/contractCapacity";
import { GetContractCapacity } from "../../common/models/types/GetContractCapacity";
import { StatusIcon, ContentLoading, queryLoad, useModal, useConfirmation } from "../../components";
import {
  Box, Button, Grid, IconButton, Tooltip,
  Typography, ButtonGroup, Paper, Popover,
  Grow, ClickAwayListener, MenuList, MenuItem
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import UpdateIcon from '@material-ui/icons/Update';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ContractCapacityFormModal } from "./contractCapacityModal";
import { ContractCapacityPriceFormModal } from "./ContractCapacityPriceFormModal";

import HistoryIcon from '@material-ui/icons/History';
import HistoryModal from "../history/HistoryModal";

import { updateContractDetailForms } from "../../state/contractSectionReducer";
import { dockForm, FormStateProps, openForm, resetForm } from "../../state/formReducer";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { ContractCapacity247Form } from "./contractCapacity247Form";
import { dateToPreferredTimezone } from "../../common/utils/date";
import ContractLeadCapacityConfig from "./contractLeadCapacityConfig";
import { updateMUITableFilterState, MuiTableStateProps } from "../../state/ldpUiTableStates";
import { toast } from "react-toastify";
import { de } from "date-fns/locale";

interface ContractCapacityProps {
  contractId: String;
  contractTabs: any;
  dispatch: Function;
  contractForms: FormStateProps;
  tableStates: MuiTableStateProps;
}

const ContractCapacity = ({ contractId, tableStates, contractForms, dispatch }: ContractCapacityProps) => {
  const [getContractCapacity, { data, loading, error, refetch }] = useLazyQuery<GetContractCapacity>(GET_CONTRACT_CAPACITY, { fetchPolicy: "cache-and-network" });
  const [deleteContractCapacityByIDs] = useMutation(DELETE_CONTRACT_CAPACITY_BY_IDS);
  const { Modal: Confirmation, closeModal: closeConfirmation, useModal: setConfirmation } = useConfirmation();

  const persistentDeleteContractCapacity = (ids: any) => {       
    ids.forEach((id: any) => {
      deleteContractCapacityByIDs({
        variables: {
          contractCapacityIds: [id],
          userId: '',
        }
      }).then((response) => {        
        const responseData = JSON.parse(response.data?.LDPConfigMutationGroup?.DeleteContractCapacity) || [];                         
        refetch();
      }).catch(() => {
        toast.error("Unexpected error!");
      });
    });        

    toast.success(`Contract Capacity deleted.`);
    // close();
  }

  const dayAnchorSelectRef = React.useRef(null);

  const LDP_TABLE_ID = `contract-capacity-daily-configuration-list-${contractId}`;

  const dayFilters = tableStates[LDP_TABLE_ID]?.others?.DayFilters === undefined ? [] : tableStates[LDP_TABLE_ID]?.others?.DayFilters;
  const daySelectedIndex = tableStates[LDP_TABLE_ID]?.others?.DaySelectedIndex === undefined ? 8 : tableStates[LDP_TABLE_ID]?.others?.DaySelectedIndex;

  const [daySelectOpen, setDaySelectOpen] = useState<boolean>(false);

  useEffect(() => {
    getContractCapacity({ variables: { where: `ContractId = ${contractId}` } });
  }, []);

  const timeFormat = (value: string) => {
    let time: Array<string> = value.split(":");
    let a = parseInt(time[0]) > 11 ? "PM" : "AM";
    time[0] = `${parseInt(time[0]) % 12}`;
    time[0] = `${parseInt(time[0]) === 0 ? 12 : time[0]}`;

    //remove seconds
    time.splice(2, 1);

    return time.join(":") + " " + a;
  }

  const columnsMUI = [
    {
      name: "ContractCapacityId",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">Edit</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {
                    if (data && data?.LDPConfigQueryGroup && data?.LDPConfigQueryGroup.ContractCapacity) {
                      openModal({
                        title: "Edit Contract Capacity",
                        icon: <EditIcon />,
                        iconColor: "orange",
                        content: (
                          <ContractCapacityFormModal
                            action="edit"
                            data={data?.LDPConfigQueryGroup.ContractCapacity.filter((item: any) => item.ContractCapacityId === value).pop()}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }
                  }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip placement="top" title={
                <React.Fragment>
                  <Typography color="textPrimary">History</Typography>
                </React.Fragment>
              }>
                <IconButton
                  onClick={() => {

                    var HISTORY_FORM_ID = `contract-${contractId}-contract-capacity-history-${value}`;

                    const formProps = {
                      formId: HISTORY_FORM_ID,
                      formTitle: `History Contract Capacity #${value}`,
                      formIcon: <HistoryIcon />,
                      formComponent: <HistoryModal ConfigName="ContractCapacity" ConfigId={value} TabContainerId={`history-contract-capacity-${value}-list-tabs`} />,
                      formData: { ConfigName: "ContractCapacity", ConfigId: value, ...tableMeta.tableData[tableMeta.rowIndex] },
                      formProps: {
                        closeTab: () => dispatch(resetForm({ formId: HISTORY_FORM_ID })),
                      }
                    };

                    dispatch(updateContractDetailForms({ contractId: `contract-${contractId}`, formIds: [HISTORY_FORM_ID] }));
                    dispatch(
                      contractForms.formContainers[HISTORY_FORM_ID]
                        && contractForms.formContainers[HISTORY_FORM_ID].isLastOpenedDocked ?
                        dockForm(formProps) : openForm(formProps)
                    );
                  }}
                >
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    // {
    //   name: "ContractCapacityId",
    //   label: "Id",
    //   options: {
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    {
      name: "ContractId",
      label: "ContractId",
      options: {
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "CreatedDate",
      label: "Created Date",
      options: {
        customBodyRender: (value: any) => {
          /* return value
            ? mument(value)
              .local()
              .format("YYYY-MM-DD")
            : ""; */
          return value
            ? dateToPreferredTimezone(value, "yyyy-MM-dd")
            : "";
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "StartTimeSecondsPastMidnight",
      label: "StartTimeSecondsPastMidnight",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "StartTime",
      label: "Start Time (PST)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any) => {
          return timeFormat(value);
        },
      },
    },
    {
      name: "EndTimeSecondsPastMidnight",
      label: "EndTimeSecondsPastMidnight",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "EndTime",
      label: "End Time (PST)",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any) => {
          return timeFormat(value);
        },
      },
    },
    {
      name: "IsActive",
      label: "IsActive",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`IsActive: ${v ? "Yes" : "No"}`) },
      },
    },
    {
      name: "IsPing",
      label: "IsPing",
      options: {
        customBodyRender: (value: any) => <StatusIcon value={value} />,
        filter: true,
        filterOptions: {
          renderValue: (v: any) => (v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`IsPing: ${v ? "Yes" : "No"}`) },
      },
    },
    {
      name: "MaxCapacity",
      label: "MaxCapacity",
      options: {
        filter: false,
      },
    },
    {
      name: "Notes",
      label: "Notes",
      options: {
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "Price",
      label: "Price Floor",
      options: {
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "ScheduleDay",
      label: "ScheduleDay",
      options: {
        filter: true,
        filterList: dayFilters,
        viewColumns: false,
      },
    },
    {
      name: "UserId",
      label: "UserId",
      options: {
        filter: false,
      },
    },
  ];

  // pick updates from redux
  useEffect(() => {
    if (!tableStates[LDP_TABLE_ID]) {
      dispatch(updateMUITableFilterState(LDP_TABLE_ID, {
        filterList: {},
        others: {
          DaySelectedIndex: 8,
        }
      }));
    } else {
      // console.log("Changes", tableStates[LDP_TABLE_ID])
    }
  }, [tableStates]);

  const options = {
    filterType: "checkbox",
    selectableRows: "multiple",
    responsive: "vertical",
    sortOrder: [
      {
        name: 'TimeStart',
        direction: 'desc',
      },
      {
        name: 'IsActive',
        direction: 'asc',
      }
    ],
    onRowsDelete: (rowsDeleted, newData) => {
      const tableData = data?.LDPConfigQueryGroup?.ContractCapacity;
      const deleteIndex = rowsDeleted.data.map((d: typeof rowsDeleted) => d.dataIndex);
      const contractCapacityIdsToDelete = tableData?.filter((item, index) => deleteIndex.includes(index)).map((d => d?.ContractCapacityId));

      setConfirmation(
        () => {                    
          persistentDeleteContractCapacity(contractCapacityIdsToDelete);
        },
        {
          title: `Are you sure you want to delete?`,
          description: "",
        }
      );
    },

  };

  /**
   * sorting data IsActive
   */
  useEffect(() => {
    data?.LDPConfigQueryGroup?.ContractCapacity?.sort((a, b) => (Number(b?.IsActive) - Number(a?.IsActive)));
  }, [data]);

  const { Modal, closeModal, openModal, setContent } = useModal();

  return (
    <React.Fragment>
      <ContractLeadCapacityConfig contractId={contractId} />
      {queryLoad([!!loading], [error]) || (
        <Grid container spacing={1}>
          <Grid item xs={12} >
            <Box p={1}>
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item sm={12} md={3}>
                  <ButtonGroup fullWidth size="large" variant="contained" color="primary" ref={dayAnchorSelectRef} aria-label="split button">
                    <Button>{daySelectedIndex > 6 ? "All Days" : Info.weekdays()[daySelectedIndex]}</Button>
                    <Button
                      color="primary"
                      aria-controls={daySelectOpen ? 'split-button-menu' : undefined}
                      aria-expanded={daySelectOpen ? 'true' : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() => {
                        setDaySelectOpen((prevOpen) => !prevOpen);
                      }}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>

                  <Popover open={daySelectOpen}
                    anchorEl={dayAnchorSelectRef.current}
                    role={undefined}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={(event) => {
                        if (dayAnchorSelectRef.current && dayAnchorSelectRef.current?.contains(event.target)) {
                          return;
                        }

                        setDaySelectOpen(false);
                      }}>
                        <MenuList id="split-button-menu">
                          {[...Info.weekdays(), "All Days"].map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === daySelectedIndex}
                              onClick={(event) => {
                                const _dayFilters: any[] = index > 6 ? Info.weekdays() : [Info.weekdays()[index]];

                                // setDaySelectedIndex(index);
                                setDaySelectOpen(false);
                                // setDayFilters(index > 6 ? Info.weekdays() : [Info.weekdays()[index]]);

                                dispatch(updateMUITableFilterState(LDP_TABLE_ID, {
                                  ...tableStates[LDP_TABLE_ID],
                                  filterList: {
                                    ...(tableStates[LDP_TABLE_ID]?.filterList || {}),
                                    ScheduleDay: _dayFilters,
                                  },
                                  others: {
                                    DayFilters: _dayFilters,
                                    DaySelectedIndex: index,
                                  }
                                }));
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Popover>
                </Grid>

                <Grid item sm={12} md={3} xl={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<AddCircle />}
                    onClick={() => {
                      openModal({
                        title: "Add New Contract Capacity",
                        icon: <AddCircle />,
                        iconColor: "orange",
                        content: (
                          <ContractCapacityFormModal
                            action="create"
                            data={{
                              ContractId: contractId,
                              ContractCapacityId: "-1"
                            }}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    Add Capacity
                  </Button>
                </Grid>
                {data?.LDPConfigQueryGroup?.ContractCapacity?.length < 1 &&
                  <Grid item sm={12} md={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      startIcon={<AddCircle />}
                      onClick={() => {
                        openModal({
                          title: "Create 24/7 Capacity Range",
                          icon: <AddCircle />,
                          iconColor: "orange",
                          content: (
                            <ContractCapacity247Form
                              data={{
                                ContractId: contractId,
                                ContractCapacityId: "-1"
                              }}
                              close={closeModal}
                              refetch={refetch}
                            />
                          ),
                        });
                      }}
                    >
                      Create 24/7 capacity range
                    </Button>
                  </Grid>
                }

                <Grid item sm={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    startIcon={<SettingsIcon />}
                    onClick={() => {
                      openModal({
                        title: "Multi-day Price Update",
                        icon: <UpdateIcon />,
                        iconColor: "orange",
                        content: (
                          <ContractCapacityPriceFormModal
                            action="updatePrice"
                            data={{
                              ContractId: contractId
                            }}
                            close={closeModal}
                            refetch={refetch}
                          />
                        ),
                      });
                    }}
                  >
                    Multi-day Price Update
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LDPUIDataTable
              ldpTableId={LDP_TABLE_ID}
              restoreFilters={true}
              title={"Daily Configuration"}
              data={data?.LDPConfigQueryGroup?.ContractCapacity}
              columns={columnsMUI}
              options={options}
            />
            <Modal />
          </Grid>
        </Grid>
      )}
      <Confirmation />
    </React.Fragment>
  );
};

export default connect((state: RootState) => ({
  contractForms: state.formsSection,
  tableStates: state.ldpUiTableStates,
}), null)(ContractCapacity);

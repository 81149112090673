import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GET_LEAD_DATA_LOOKUP_CONFIG } from "../../common/models/config";
import {
  GET_CONTRACT_FILTER_OPERATION,
  GET_EXTERNAL_PROCEDURE,
  SAVE_CONTRACT_FILTER,
} from "../../common/models/contractFilters";
import { GET_CONTRACTS } from "../../common/models/contracts";
import { GET_LEAD_FIELD_DATA } from "../../common/models/leadDataLookup";
import { GET_TABLE_DEFINITION } from "../../common/models/tableDefinition";
import { GetContractFilterOperation } from "../../common/models/types/GetContractFilterOperation";
import { GetContractFilters_LDPConfigQueryGroup_ContractFilter as ContractFilter } from "../../common/models/types/GetContractFilters";
import {
  GetContracts,
  GetContracts_LDPConfigQueryGroup_Contract,
} from "../../common/models/types/GetContracts";
import { GetExternalProcedures } from "../../common/models/types/GetExternalProcedures";
import { GetLeadFieldData } from "../../common/models/types/GetLeadFieldData";
import { GetTableDefinition } from "../../common/models/types/GetTableDefinition";
import { LeadDataLookupConfig } from "../../common/models/types/LeadDataLookupConfig";
import { convertString } from "../../common/utils";
import { removeDuplicates } from "../../common/utils/array";
import {
  DecimalPattern as testDecimal,
  SignedInteger as testNumeric,
} from "../../common/utils/regex";
import { queryLoad } from "../../components";
import { ContractFilterInputType } from "../../types/graphql-global-types";

interface ContractFilterFormModalProps {
  data: ContractFilter;
  filters: Array<ContractFilter>;
  action: string;
  close: Function;
  refetch: Function;
}
interface MappedSelectField {
  id: number;
  label: string;
}

export const ContractFilterFormModal = ({
  action,
  data,
  filters,
  close,
  refetch,
}: ContractFilterFormModalProps) => {
  const classes = useStyles();
  const pageTitle =
    (action == "create" ? "Add New" : "Edit") + " Contract Filter";

  const [saveContractFilter] = useMutation(SAVE_CONTRACT_FILTER);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    control,
    setValue,
  } = useForm<ContractFilterInputType>();
  const [delimiter, setDelimiter] = useState<any>(undefined);
  const [delimiterError, setDelimiterError] = useState<string | boolean>(false);

  const [
    getAllContractFilterOperation,
    {
      data: contractFilterOperationData,
      error: contractFilterOperationError,
      loading: contractFilterOperationLoading,
    },
  ] = useLazyQuery<GetContractFilterOperation>(GET_CONTRACT_FILTER_OPERATION);

  const [
    getExternalProcedures,
    { data: contractProcedureData, loading: contractProcedureLoading },
  ] = useLazyQuery<GetExternalProcedures>(GET_EXTERNAL_PROCEDURE);

  const [getContract, { data: contractData }] = useLazyQuery<GetContracts>(
    GET_CONTRACTS
  );
  const [
    getLeadDataLookupConfig,
    { data: leadDataLookupConfigData },
  ] = useLazyQuery<LeadDataLookupConfig>(GET_LEAD_DATA_LOOKUP_CONFIG);
  const [
    getTableDefinition,
    { data: tableDefinitionData },
  ] = useLazyQuery<GetTableDefinition>(GET_TABLE_DEFINITION);
  useEffect(() => {
    if (!contractData) {
      getContract({
        variables: {
          where: `ContractId = ${data.ContractId}`,
        },
      });
    } else {
      const contract: GetContracts_LDPConfigQueryGroup_Contract | null =
        contractData?.LDPConfigQueryGroup?.Contract?.[0] ?? null;
      if (contract) {
        if (!leadDataLookupConfigData) {
          getLeadDataLookupConfig({
            variables: {
              where: `VerticalId = ${contract?.Vertical?.VerticalId} AND SubVerticalId = ${contract?.SubVertical?.SubVerticalId}`,
            },
          });
        }

        if (!tableDefinitionData) {
          getTableDefinition({
            variables: {
              schema: "lead",
              name: `${contract?.Vertical?.VerticalName}${contract?.SubVertical?.SubVerticalName}`,
            },
          });
        }
      }
    }
  }, [contractData]);

  const [fieldNameOptions, setFieldNameOptions] = useState<string[]>([]);
  useEffect(() => {
    let fields: string[] = [];
    leadDataLookupConfigData?.LDPConfigQueryGroup?.LeadDataLookupConfig?.map(
      (it: any) => fields.push(it?.ColumnName)
    );

    tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.map((it: any) =>
      fields.push(it?.ColumnName)
    );

    fields = fields.filter((v, i, a) => a.indexOf(v) === i);
    fields.sort();

    setFieldNameOptions(fields);
  }, [leadDataLookupConfigData, tableDefinitionData]);

  const [
    getFieldDefaultValues,
    { data: fieldDefaultValues },
  ] = useLazyQuery<GetLeadFieldData>(GET_LEAD_FIELD_DATA);
  const [fieldDefaultValueOptions, setFieldDefaultValueOptions] = useState<
    string[]
  >([]);
  const [
    selectedFieldDefaultValueOption,
    setSelectedFieldDefaultValueOption,
  ] = useState(data?.Value || null);
  const [fieldName, setFieldName] = useState<any>(null);

  useEffect(() => {
    getAllContractFilterOperation();
    getFieldDefaultValues();
  }, []);

  register("ProcedureDescription");
  register("ProcedureParameterName");
  register("ExternalProcedureName");

  const [formData, setFormData] = useState<ContractFilter | any>(null);
  useEffect(() => {
    setFormData(data);
    //Dont forget to setFieldName initial value! (when editing)
    setFieldName(data?.FieldName);
    setDelimiter(data?.Delimiter);

    if (data.ContractId) {
      getContract({
        variables: { where: `ContractId = ${data?.ContractId}` },
      });
    }

    if (data.FieldName) {
      getExternalProcedures({
        variables: {
          FieldName: data?.FieldName,
          IsContractFilter: 1,
        },
      });
    }

    setValue("ProcedureDescription", data?.ProcedureDescription);
    setValue("ExternalProcedureName", data?.ExternalProcedureName);
    setValue("ProcedureParameterName", data?.ProcedureParameterName);
  }, [data]);

  const [
    mappedContractFilterOperation,
    setMappedContractFilterOperation,
  ] = useState<MappedSelectField[]>();
  const [
    selectedContractFilterOperation,
    setSelectedContractFilterOperation,
  ] = useState<MappedSelectField | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (contractFilterOperationData) {
      setMappedContractFilterOperation(
        contractFilterOperationData?.LDPConfigQueryGroup?.FilterOperation?.map(
          (x) => {
            return {
              id: x?.FilterOperationId,
              label: `${x?.Operation}`,
            };
          }
        )
      );
    }
  }, [contractFilterOperationData]);

  useEffect(() => {
    if (fieldDefaultValues) {
      setFieldDefaultValueOptions(
        removeDuplicates(
          fieldDefaultValues.LDPIngestQueryGroup?.LeadDataLookup?.filter(
            (v) => !convertString.isNullOrUndefined(v?.LeadDataValue)
          ).map((v) => `${v?.LeadDataValue}`) || []
        )
      );
    }
  }, [fieldDefaultValues]);

  useEffect(() => {
    const selected =
      mappedContractFilterOperation?.find(
        (contractFilterOperation: MappedSelectField) =>
          contractFilterOperation?.label.toLowerCase() ===
          formData?.Operation?.toLowerCase()
      ) || null;
    setSelectedContractFilterOperation(selected);
    setLoading(false);
  }, [mappedContractFilterOperation]);

  const [disableButtons, setDisableButtons] = useState<Boolean>(false);
  const onSubmit = (contractFilterInput: ContractFilterInputType) => {
    const operation = contractFilterInput?.Operation?.toLowerCase() ?? "";
    const value = contractFilterInput?.Value?.toLowerCase() ?? "";

    if (operation.search("contain") > -1) {
      if (!delimiter) return setDelimiterError("Delimiter is required");

      if (delimiter && value.split(delimiter).length <= 1)
        return setDelimiterError(
          "Please set the correct delimeter based from the declared value"
        );
    } else {
      if (delimiter && value.split(delimiter).length <= 1)
        setDelimiterError("No delimiter needed for single value");
    }

    let finalContractFilterInput = contractFilterInput;
    let error = "";

    //get Delimiter from state variable as there seemed to be a bug passing through register/useForm
    finalContractFilterInput.Delimiter = delimiter;
    finalContractFilterInput.IsList = delimiter ? true : false;

    setDisableButtons(true);

    const DataType = tableDefinitionData?.LDPIngestQueryGroup?.TableDefinition?.find(
      (tableDefinition) =>
        tableDefinition?.ColumnName === finalContractFilterInput.FieldName
    )?.DataType?.split("(")[0]; // DataType returning demical(x,x)

    //Set form error based on expected Datatype input
    switch (DataType) {
      case "int":
      case "bigint":
        if (
          finalContractFilterInput.Delimiter &&
          contractFilterInput?.Value?.includes(
            finalContractFilterInput.Delimiter
          )
        ) {
          let values = contractFilterInput?.Value?.split(
            finalContractFilterInput.Delimiter
          );
          if (values) {
            values.map((value) => {
              if (!testNumeric.test(value)) {
                error = `Field Name ${contractFilterInput.FieldName} required a number value`;
                setError("Value", {
                  type: "manual",
                  message: error,
                });
              }
            });
          }
        } else {
          if (!testNumeric.test(contractFilterInput?.Value ?? "")) {
            error = `Field Name ${contractFilterInput.FieldName} required a number value`;
            setError("Value", {
              type: "manual",
              message: error,
            });
          }
        }
        break;
      case "decimal":
        if (!testDecimal.test(contractFilterInput?.Value ?? "")) {
          error = `Field Name ${contractFilterInput.FieldName} required a decimal value`;
          setError("Value", {
            type: "manual",
            message: error,
          });
        }
        break;
    }

    // if (!contractFilterInput.ProcedureDescription) {
    //   error = "Description must be specified!"
    // }

    // if (contractFilterInput.IsNumeric && !contractFilterInput.IsList) {
    //   if (!((!isNaN(parseFloat(contractFilterInput?.Value)) && isFinite(contractFilterInput?.Value))))
    //     error = "Value must be numeric!";
    // }

    // if (contractFilterInput.IsDecimal && !contractFilterInput.IsList) {
    //   if (!((contractFilterInput?.Value % 1) > 0))
    //     error = "Value must be decimal!";
    // }

    if (error !== "") {
      setDisableButtons(false);
      return toast.error(error);
    }

    if (
      finalContractFilterInput.Delimiter &&
      finalContractFilterInput?.Value?.includes(
        finalContractFilterInput.Delimiter
      )
    ) {
      let values = contractFilterInput?.Value?.split(
        finalContractFilterInput.Delimiter
      );
      if (values) {
        values.map((value) => {
          //Check and set if Default value isDecimal
          if (testDecimal.test(value) && DataType === "decimal") {
            finalContractFilterInput = {
              ...finalContractFilterInput,
              IsDecimal: true,
            };
          } else {
            finalContractFilterInput = {
              ...finalContractFilterInput,
              IsDecimal: false,
            };
          }
          //Check and set if Default value isNumeric
          if (
            testNumeric.test(value) &&
            ["int", "bigint", "decimal"].includes(DataType ?? "")
          ) {
            finalContractFilterInput = {
              ...finalContractFilterInput,
              IsNumeric: true,
            };
          } else {
            finalContractFilterInput = {
              ...finalContractFilterInput,
              IsNumeric: false,
            };
          }
        });
      }
    } else {
      //Check and set if Default value isDecimal
      if (
        testDecimal.test(contractFilterInput?.Value ?? "") &&
        DataType === "decimal"
      ) {
        finalContractFilterInput = {
          ...finalContractFilterInput,
          IsDecimal: true,
        };
      } else {
        finalContractFilterInput = {
          ...finalContractFilterInput,
          IsDecimal: false,
        };
      }

      //Check and set if Default value isNumeric
      if (
        testNumeric.test(contractFilterInput?.Value ?? "") &&
        ["int", "bigint", "decimal"].includes(DataType ?? "")
      ) {
        finalContractFilterInput = {
          ...finalContractFilterInput,
          IsNumeric: true,
        };
      } else {
        finalContractFilterInput = {
          ...finalContractFilterInput,
          IsNumeric: false,
        };
      }
    }

    finalContractFilterInput.UserId = ""; // let API record the value

    //return console.log(finalContractFilterInput);
    saveContractFilter({
      variables: {
        contractFilterInput: finalContractFilterInput,
      },
    })
      .then((response: any) => {
        toast.success(
          `Contract Filter ${
            contractFilterInput?.ContractFilterId > 0 ? "update" : "created"
          } successfully.`
        );
        setDisableButtons(false);
        refetch();
        close();
      })
      .catch(({ errors }) => {
        setDisableButtons(false);
        toast.error("Unabled to save filter. Duplicate entry is not allowed!");
      });
  };

  /* const listNumericDecimalToggle = (
    raiseAttr: "numeric" | "list" | "decimal"
  ) => {
    if (raiseAttr === "list") {
      setFormData({
        ...formData,
        IsList: true,
        IsNumeric: false,
        IsDecimal: false,
      });
    } else if (raiseAttr === "numeric") {
      setFormData({
        ...formData,
        IsList: false,
        IsNumeric: true,
        IsDecimal: false,
      });
    } else {
      setFormData({
        ...formData,
        IsList: false,
        IsNumeric: false,
        IsDecimal: true,
      });
    }
  }; */

  const ContractFilterConstraints = {
    ContractFilterName: {
      required: {
        value: true,
        message: "Filter Name is required.",
      },
    },
    FieldName: {
      required: {
        value: true,
        message: "Field Name is required.",
      },
    },
    Operation: {
      required: {
        value: true,
        message: "Operation is required.",
      },
    },
    Value: {
      required: {
        value: true,
        message: "Value is required.",
      },
    },
    Delimiter: {
      required: {
        value: false,
        message: "",
      },
    },
  };

  const [warnFieldName, setWarnFieldName] = useState<string | undefined>(
    undefined
  );
  const fieldNameWarnDuplicate = (value: string | null) => {
    setWarnFieldName(undefined);
    if (
      value &&
      value?.toLowerCase() !== "age" &&
      filters.find(
        (it) =>
          it.FieldName === value &&
          it.ContractFilterId !== data?.ContractFilterId
      )
    ) {
      //return toast.warn(`Warning: The field name "${value}" is already used.`);
      setWarnFieldName(value);
    }
  };

  return (
    <Paper className={classes.container}>
      {queryLoad(
        [!!contractFilterOperationLoading, !!loading],
        [contractFilterOperationError]
      ) || (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.mainGrid} container spacing={2}>
            <Grid item xs>
              <TextField
                required
                hidden
                inputRef={register}
                name="ContractFilterId"
                defaultValue={formData?.ContractFilterId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="ContractId"
                defaultValue={formData?.ContractId ?? 0}
              />
              <TextField
                required
                hidden
                inputRef={register}
                name="UserId"
                defaultValue={formData?.UserId ?? "Test User"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                inputRef={register(
                  ContractFilterConstraints.ContractFilterName
                )}
                error={errors.ContractFilterName && true}
                helperText={
                  errors.ContractFilterName &&
                  errors.ContractFilterName?.message
                }
                name="ContractFilterName"
                label="Contract Filter Name"
                defaultValue={formData?.ContractFilterName ?? ""}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="fieldname-id-input"
                options={fieldNameOptions}
                value={fieldName}
                getOptionLabel={(option) => option}
                // disabled={! leadLookupConfigData}
                onChange={(event: any, newValue: any) => {
                  setFieldDefaultValueOptions([]);
                  setSelectedFieldDefaultValueOption(null);
                  //console.log("newValue", newValue);
                  if (newValue) {
                    //console.log("getFieldDefaultValues", `LeadDataColumnName="${newValue}"`);
                    getFieldDefaultValues({
                      variables: { where: `LeadDataColumnName="${newValue}"` },
                    });
                  }
                  setFieldName(newValue);

                  getExternalProcedures({
                    variables: {
                      FieldName: newValue,
                      IsContractFilter: 1,
                    },
                  });

                  fieldNameWarnDuplicate(newValue);

                  setValue("ProcedureDescription", "");
                  setValue("ExternalProcedureName", "");
                  setValue("ProcedureParameterName", "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputRef={register(ContractFilterConstraints.FieldName)}
                    error={errors.FieldName && true}
                    helperText={errors.FieldName && errors.FieldName?.message}
                    name="FieldName"
                    label="Field Name"
                    variant="outlined"
                  />
                )}
              />
              {warnFieldName && (
                <Alert severity="warning" style={{ marginTop: "5px" }}>
                  Warning: The field name "<strong>{warnFieldName}</strong>" is
                  already used.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="contract-filter-operation-id-input"
                options={mappedContractFilterOperation || []}
                getOptionLabel={(option) => option.label}
                value={selectedContractFilterOperation}
                onChange={(event: any, newValue: MappedSelectField | null) => {
                  setSelectedContractFilterOperation(newValue);
                  setFormData({ ...formData, Operation: newValue?.label });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Operation"
                    variant="outlined"
                    error={errors.Operation ? true : false}
                    helperText={errors.Operation && errors.Operation?.message}
                    inputRef={register(ContractFilterConstraints.Operation)}
                    name="Operation"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="delimiter-select-label">Delimiter</InputLabel>
                <Select
                  name="Delimiter"
                  // defaultValue={}
                  error={Boolean(delimiterError)}
                  label="Delimiter"
                  value={delimiter ?? ""}
                  onChange={(e) => setDelimiter(e.target.value)}
                >
                  <MenuItem value={undefined}>No delimeter</MenuItem>
                  <MenuItem value="|">|</MenuItem>
                  <MenuItem value=",">,</MenuItem>
                  <MenuItem value="^">^</MenuItem>
                </Select>
                {delimiterError && (
                  <p
                    style={{
                      color: "#f44336",
                      marginLeft: "15px",
                      marginTop: "6px",
                      marginBottom: 0,
                    }}
                  >
                    {delimiterError}
                  </p>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="contract-filter-default-value-input"
                options={fieldDefaultValueOptions || []}
                value={selectedFieldDefaultValueOption}
                onChange={(event: any, newValue: any) => {
                  setSelectedFieldDefaultValueOption(newValue);
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Default Value"
                    variant="outlined"
                    inputRef={register(ContractFilterConstraints.Value)}
                    error={errors.Value && true}
                    helperText={errors.Value && errors.Value?.message}
                    name="Value"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="ProcedureDescription-select-label">
                  Procedure Description
                </InputLabel>
                <Select
                  name="ProcedureDescription"
                  defaultValue={data?.ProcedureDescription ?? ""}
                  error={errors.ProcedureDescription && true}
                  label="Description"
                  onChange={(e: BaseSyntheticEvent) => {
                    setValue("ProcedureDescription", e.target.value);
                  }}
                >
                  {contractProcedureData &&
                    contractProcedureData?.LDPConfigQueryGroup?.GetExternalProcedures?.map(
                      (it, n) => (
                        <MenuItem
                          key={n}
                          value={it?.Description ?? ""}
                          onClick={() => {
                            setValue(
                              "ExternalProcedureName",
                              it?.ProcedureName ?? ""
                            );
                            setValue(
                              "ProcedureParameterName",
                              it?.ProcedureParameterName ?? ""
                            );
                          }}
                        >
                          {it?.Description}
                        </MenuItem>
                      )
                    )}
                </Select>
                {errors.ProcedureDescription && (
                  <p
                    style={{
                      color: "#f44336",
                      marginLeft: "15px",
                      marginTop: "6px",
                      marginBottom: 0,
                    }}
                  >
                    {errors.ProcedureDescription?.message}
                  </p>
                )}
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                  inputRef={register}
                  error={errors.ProcedureParameterName && true}
                  helperText={
                    errors.ProcedureParameterName &&
                    errors.ProcedureParameterName?.message
                  }
                  name="ProcedureParameterName"                  
                  label="Procedure Parameter"
                  defaultValue={data?.ProcedureParameterName || ''}                                    
                  variant="outlined"
                />
              </Grid> */}
            {/* <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      inputRef={register}
                      onClick={(evt: React.MouseEvent) => {
                        if (formData?.IsNumeric) {
                          setFormData({ ...formData, IsNumeric: false });
                        } else {
                          listNumericDecimalToggle('numeric');
                        }
                      }}
                      defaultChecked={formData?.IsNumeric ?? false}
                      checked={formData?.IsNumeric}
                      name="IsNumeric"
                      color="primary"
                    />
                  }
                  label="Is Numeric"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      inputRef={register}
                      onClick={(evt: React.MouseEvent) => {
                        if (formData?.IsDecimal) {
                          setFormData({ ...formData, IsDecimal: false });
                        } else {
                          listNumericDecimalToggle('decimal');
                        }

                      }}
                      defaultChecked={formData?.IsDecimal ?? false}
                      checked={formData?.IsDecimal}
                      name="IsDecimal"
                      color="primary"
                    />
                  }
                  label="Is Decimal"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      inputRef={register}
                      onClick={(evt: React.MouseEvent) => {
                        if (formData?.IsList) {
                          setFormData({ ...formData, IsList: false });
                        } else {
                          listNumericDecimalToggle('list');
                        }

                      }}
                      defaultChecked={formData?.IsList ?? false}
                      checked={formData?.IsList}
                      name="IsList"
                      color="primary"
                    />
                  }
                  label="Is List"
                />
              </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.IsPing ?? true}
                    name="IsPing"
                    color="primary"
                  />
                }
                label="Is Ping"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    inputRef={register}
                    defaultChecked={formData?.IsActive ?? false}
                    name="IsActive"
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={disableButtons}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: "left",
      position: "relative",
      minHeight: "300px",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

import gql from "graphql-tag";

export const GET_CONTRACT_RESPONSE_PARSING = gql`
    query GetContractResponseParsing($where: String){
        LDPConfigQueryGroup {
            ContractResponseParsing(limit:9999, where: $where) {
                ContractId
                ContractResponseParsingId
                CreatedDate
                IsActive
                IsPing
                IsWordBoundaryMatch
                ModifiedDate
                OrderOfOperation
                ResponseDispositionId
                ResponseValue
                ResponseValueIsCaseSensitive
                UserId
            }
        }
    }
`;  

export const SAVE_CONTRACT_RESPONSE_PARSING = gql`
    mutation SaveContractResponseParsing($contractResponseParsingData: ContractResponseParsingInputType!){
        LDPConfigMutationGroup {
            SaveContractResponseParsing(ContractResponseParsingData : $contractResponseParsingData)
        }
    }
`;
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SAVE_CONTRACT_LEAD_CAPACITY_CONFIG } from "../../common/models/contractLeadCapacityConfig";
import { GET_IS_AFFILIATE_ID_ACTIVE } from "../../common/models/contractMonetaryCapacity";
import { MergeContractLeadCapacityConfigInputType } from "../../types/graphql-global-types";

interface ContractLeadCapacityConfigFormProps {
  action: string;
  data: MergeContractLeadCapacityConfigInputType | any;
  close: Function;
  refetch: Function;
  existingAffiliateIds: (number | null)[];
}

export const ContractLeadCapacityConfigForm = ({
  action,
  data,
  close,
  refetch,
  existingAffiliateIds,
}: ContractLeadCapacityConfigFormProps) => {
  const [saveContractLeadCapacityConfig] = useMutation(
    SAVE_CONTRACT_LEAD_CAPACITY_CONFIG
  );
  const [
    isAffiliateIdActive,
    { data: isAffiliateIdActiveData, loading: isAffiliateIdActiveLoading },
  ] = useLazyQuery(GET_IS_AFFILIATE_ID_ACTIVE);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    setError,
    clearErrors,
    getValues,
  } = useForm();
  const classes = useStyles();

  const [saveState, setSaveState] = useState<
    "preparing" | "validating" | "validated" | "ready"
  >("preparing");

  const watchAffiliateId = watch("AffiliateId");

  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  useEffect(() => {
    if (
      saveState === "validating" &&
      !isAffiliateIdActiveLoading &&
      isAffiliateIdActiveData
    ) {
      //this step ensures that API AffiliateId validation is complete before setting field errors
      setSaveState("validated");
    } else if (
      saveState === "validated" &&
      watchAffiliateId &&
      isAffiliateIdActiveData
    ) {
      if (isAffiliateIdActiveData.LDPConfigQueryGroup?.GetIsAffiliateIdActive) {
        setSaveState("ready");
      } else {
        setSaveState("preparing");
        setError("AffiliateId", {
          type: "error",
          message: "AffiliateId is inactive, please set a different value",
        });
        setDisableButtons(false);
      }
    } else if (saveState === "ready" && watchAffiliateId) {
      handleSaveContractLeadCapacity();
    }
  }, [
    isAffiliateIdActiveLoading,
    saveState,
    isAffiliateIdActiveData,
    watchAffiliateId,
  ]);

  const handleSaveContractLeadCapacity = async () => {
    const formData = getValues();
    const contractLeadCapacityConfigInput = {
      ...formData,
      AffiliateId: formData.AffiliateId || null,
      MaxMonthlyBudget: formData.MaxMonthlyBudget || null,
      MaxWeeklyBudget: formData.MaxWeeklyBudget || null,
      MaxDailyBudget: formData.MaxDailyBudget || null,
      UserId: '',
    };

    const result = await saveContractLeadCapacityConfig({
      variables: {
        contractLeadCapacityConfigInput,
      },
    })
      .then((response) => {
        // evaluate responses here
        let allSuccess =
          response.data?.LDPConfigMutationGroup
            ?.MergeContractLeadCapacityConfig;

        if (allSuccess) {
          toast.success(
            `Contract Lead Capacity ${
              formData.ContractLeadCapacityConfigId > 0 ? "updated" : "created"
            } successfully.`
          );
          return true;
        } else {
          toast.error("Error saving contract monetary capacity configuration");
          return false;
        }
      })
      .catch(({ errors }) => {
        //toast.error("Unexpected error!");
        return false;
      });

    setDisableButtons(false);

    if (result) {
      refetch();
      close();
    }
  };

  const onSubmit = async (data: MergeContractLeadCapacityConfigInputType) => {
    setDisableButtons(true);
    if (data.AffiliateId) {
      //check affiliateId if valid
      setSaveState("validating");
      isAffiliateIdActive({
        variables: {
          affiliateId: data.AffiliateId,
        },
      });
    } else {
      handleSaveContractLeadCapacity();
    }
    return false;
  };

  const ContractLeadCapacityConfigConstraints = {
    MaxMonthlyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Monthly Budget value.",
      },
    },
    MaxWeeklyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Weekly Budget value.",
      },
    },
    MaxDailyBudget: {
      pattern: {
        value: /^\d*(\.\d{0,2})?$/s, //allows only numbers with an optional 2 decimal places
        message: "Invalid Max Daily Budget value.",
      },
      min: {
        value: 0,
        message: "Minimum value is 0",
      },
    },
    AffiliateId: {
      pattern: {
        value: /^[1-9]\d*$/s,
        message: "Invalid Affiliate Id",
      },
      maxLength: {
        value: 5,
        message: "Invalid Affiliate Id",
      },
      validate: (value: string) => {
        if (value)
          return existingAffiliateIds.includes(parseInt(value))
            ? `AffiliateId: ${value} is already in use`
            : true;
        else
          return existingAffiliateIds.includes(null)
            ? `AffiliateId: null is already in use`
            : true;
      },
    },
    // AffiliateId: {
    //   required: "Affiliate Id is required",
    //   pattern: {
    //     value: /^[0-9]+$/,
    //     message: "Affiliate Id must be a number",
    //   },
    //   validate: (value: string) => {
    //     // if (!value) return true;
    //     if (
    //       !isAffiliateIdActiveData?.LDPConfigQueryGroup.GetIsAffiliateIdActive
    //     ) {
    //       return "Affiliate Id is inactive";
    //     }
    //     return true;
    //   },
    // },
  };

  return (
    <Paper className={classes.contrainer}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={classes.mainGrid} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractLeadCapacityConfigId"
              defaultValue={data?.ContractLeadCapacityConfigId ?? 0}
            />
            <TextField
              type="number"
              inputRef={register(
                ContractLeadCapacityConfigConstraints.AffiliateId
              )}
              name="AffiliateId"
              label="Affiliate Id"
              defaultValue={data?.AffiliateId ?? ""}
              error={errors.AffiliateId && true}
              helperText={errors.AffiliateId && errors.AffiliateId?.message}
              variant="outlined"
              // disabled={data?.AffiliateId}
              InputProps={{ readOnly: data?.AffiliateId }}
              // onBlur={() => {
              //   handleCheckAffililateId();
              // }}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="ContractId"
              defaultValue={data?.ContractId ?? 0}
            />
            <TextField
              required
              hidden
              inputRef={register}
              name="UserId"
              defaultValue={data?.UserId ?? "Test User"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(
                ContractLeadCapacityConfigConstraints.MaxMonthlyBudget
              )}
              // inputRef={register}
              error={errors.MaxMonthlyCapacity && true}
              helperText={
                errors.MaxMonthlyCapacity && errors.MaxMonthlyCapacity?.message
              }
              name="MaxMonthlyBudget"
              label="Max Monthly Capacity"
              defaultValue={
                data?.MaxMonthlyCapacity === 0 ? "" : data?.MaxMonthlyCapacity
              }
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(
                ContractLeadCapacityConfigConstraints.MaxWeeklyBudget
              )}
              // inputRef={register}
              error={errors.MaxWeeklyCapacity && true}
              helperText={
                errors.MaxWeeklyCapacity && errors.MaxWeeklyCapacity?.message
              }
              name="MaxWeeklyBudget"
              label="Max Weekly Capacity"
              defaultValue={
                data?.MaxWeeklyCapacity === 0 ? "" : data?.MaxWeeklyCapacity
              }
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(
                ContractLeadCapacityConfigConstraints.MaxWeeklyBudget
              )}
              // inputRef={register}
              error={errors.MaxDailyCapacity && true}
              helperText={
                errors.MaxDailyCapacity && errors.MaxDailyCapacity?.message
              }
              name="MaxDailyBudget"
              label="Max Daily Capacity"
              defaultValue={
                data?.MaxDailyCapacity === 0 ? "" : data?.MaxDailyCapacity
              }
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  inputRef={register}
                  defaultChecked={data?.IsActive ?? false}
                  name="IsActive"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              type="button"
              size="large"
              fullWidth
              onClick={() => close()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={disableButtons}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contrainer: {
      textAlign: "left",
    },
    mainGrid: {
      padding: "20px",
    },
    pagetitle: {
      padding: "20px",
      color: "white",
      background: "#457373",
    },
    root: {
      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  })
);

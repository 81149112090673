import gql from "graphql-tag";

export const GET_ALL_BUYERGROUPS = gql`
    query GetAllBuyerGroups($where: String){
        LDPConfigQueryGroup {
            BuyerGroup(limit:9999, where: $where) {
                BuyerGroupId
                BuyerGroupName
                CreatedDate
                ModifiedDate
                Position
                SubVerticalId
                UserId
                VerticalId
                OverrideExclusiveSale
            }
        }
    }
`;  

export const SAVE_BUYER_GROUP = gql`
    mutation SaveBuyerGroup($buyerGroupInput: MergeBuyerGroupInputType!) {
        LDPConfigMutationGroup {
            MergeBuyerGroup(MergeBuyerGroupParams: $buyerGroupInput)
        }
    }
`;
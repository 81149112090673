import gql from "graphql-tag";

export const GET_CONTRACT_RESPONSE_TOKEN = gql`
    query GetContractResponseToken($where: String){
        LDPConfigQueryGroup {
            ContractResponseToken(limit:9999, where: $where) {
                ContractId
                ContractResponseTokenId
                CreatedDate
                IsActive
                IsHtml
                IsPing
                ModifiedDate
                TokenName
                TokenPath
                TokenPathIsCaseSensitive
                TokenType
                UserId
            }
        }
    }
`; 

export const SAVE_CONTRACT_RESPONSE_TOKEN = gql`
    mutation SaveContractResponseToken($data:ContractResponseTokenInputType!) {
        LDPConfigMutationGroup {
            SaveContractResponseToken(ContractResponseTokenData: $data)
        }
    }
`;